import React from 'react';
import Introduction from '../components/common/Introduction';
import Breadcrumbs from '../components/common/Breadcrumbs';

import Arguments from '../components/Strengths/Arguments';
import Points from '../components/Strengths/Points';
import Reviews from '../components/Strengths/Reviews';
import Links from '../components/Strengths/Links';
import { pages } from '../config/pages';

const StrengthsContainer: React.FC<{}> = () => {
  return (
    <div>
      <Introduction title={pages.strengths.name}>
        <p>
          H.R.D Funeralは、地域の皆様に寄り添い、
          <br className='mid:hidden' />
          真心こめて「心のかようお葬儀」の場としてご提供しています。
        </p>
        <p>
          培ってきた経験とサービス品質に加え、
          <br className='mid:hidden' />
          細やかな気配りからなる、
          <br className='mid:hidden' />
          年間6,500件のお葬儀をお手伝いしております。
        </p>
      </Introduction>
      <Breadcrumbs subPages={[pages.strengths]} />
      <Arguments />
      <Points />
      <Reviews />
      <Links />
    </div>
  );
};
<Arguments />;

export default StrengthsContainer;
