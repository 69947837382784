import { Link } from 'react-router-dom';
import { pages } from '../../config/pages';
import JapanMap from './JapanMap';
import { prefectures } from '../../config/constants';

const Introduction = () => {
  return (
    <>
      <div className='sp:hidden'>
        <div className='flex flex-row'>
          <div className="flex justify-center items-center bg-[url('/public/images/default_bg_home.png')] bg-repeat-x relative h-[697px] min-w-[50%]">
            <div className='flex flex-col items-center'>
              <img
                src='/images/kokoro.png'
                alt='kokoro'
                className='h-[226px] mb-[27px] z-10'
              />
              <p className='text-[#333333] text-[16px] text-center font-bold font-serif leading-loose z-10'>
                お葬式は経験のないほとんどの方が初心者です。
                <br />
                ベテランスタッフが、
                <br />
                ご遺族のお気持ちに寄り添い丁寧にサポートいたします。
                <br />
                費用のこと式場のことはもちろん、
                <br />
                お葬式に関わるどんなことでもお気軽にお尋ねください。
              </p>
            </div>
            <img
              className='absolute w-[330px] object-contain top-[0px] left-[0px]'
              src='/images/lotos_with_cloud_left.png'
              alt='蓮華の画像'
            />
            <img
              className='absolute h-[109px] object-contain top-[28px] right-[0px]'
              src='/images/cloud.png'
              alt='雲の画像'
            />
            <img
              className='absolute h-[188px] object-contain bottom-[20px] right-[20px]'
              src='/images/lotos_right.png'
              alt='蓮華の画像'
            />
          </div>
          <div className='flex items-center justify-center mx-[30px]'>
            <JapanMap />
          </div>
        </div>
      </div>
      <div className='pc:hidden'>
        <div className="flex justify-center items-center bg-[url('/public/images/default_bg_home.png')] bg-repeat-x relative h-[500px]">
          <div className='flex flex-col items-center'>
            <img
              src='/images/kokoro.png'
              alt='kokoro'
              className='h-[144px] mb-[27px] z-10'
            />
            <p className='text-[13px] font-bold font-serif text-center leading-loose z-10'>
              お葬式は経験のないほとんどの方が初心者です。
              <br />
              ベテランスタッフが、
              <br />
              ご遺族のお気持ちに寄り添い丁寧にサポートいたします。
              <br />
              費用のこと式場のことはもちろん、
              <br />
              お葬式に関わるどんなことでもお気軽にお尋ねください。
            </p>
          </div>
          <img
            className='absolute w-[179px] object-contain top-[0px] left-[0px]'
            src='/images/lotos_with_cloud_left.png'
            alt='蓮華の画像'
          />
          <img
            className='absolute w-[140px] object-contain top-[10px] right-[0px]'
            src='/images/cloud.png'
            alt='雲の画像'
          />
          <img
            className='absolute h-[106px] object-contain bottom-[11px] right-[11px]'
            src='/images/lotos_right.png'
            alt='蓮華の画像'
          />
        </div>
        <div className='flex flex-col items-center mx-[20px] mt-[30px]'>
          <p className='text-primary text-[29px] font-serif font-bold'>
            式場検索
          </p>
          <img
            src='/images/search_halls.png'
            alt='search_halls'
            className='max-w-[500px] w-full mt-[15px] mb-[10px]'
          />
          <p className='text-[14px] mb-[25px]'>
            都道府県から ご希望のホールをお探しいただけます。
          </p>
          <div className='grid grid-cols-2 gap-[10px] w-full max-w-[434px]'>
            {prefectures.map((prefecture) => (
              <PrefectureLabel key={prefecture} linkName={prefecture}>
                {prefecture}
              </PrefectureLabel>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const PrefectureLabel: React.FC<{
  children: React.ReactNode;
  linkName: string;
}> = ({ children, linkName }) => (
  <Link to={`${pages.halls.path}?prefecture=${linkName}`}>
    <div className='max-w-[200px] py-[5px] border-[2px] border-primary rounded-[25px] shadow-normal'>
      <p className='text-primary text-[22px] text-center'>{children}</p>
    </div>
  </Link>
);

export default Introduction;
