import React from 'react';
import Tab from '../Tab';
import SubHeading from '../../common/SubHeading';
import TextBlock from '../../common/TextBlock';
import PrivateFuneralFlow from './PrivateFuneralFlow';
import FuneralExample from '../FuneralExample';
import GoldWindow from '../GoldWindow';
import LargeLinkButton from '../LargeLinkButton';
import { pages } from '../../../config/pages';

const PrivateFuneralTab: React.FC<{}> = () => {
  return (
    <Tab funeralType='private'>
      <div className='bg-contrastBg w-full'>
        <div className=' py-[70px] max-w-[1066px] mx-auto px-[20px]'>
          <SubHeading>家族葬の流れとポイント</SubHeading>
          <TextBlock className='mb-[30px]'>
            <p>
              家族葬の一般的な流れとともに、準備や対応にあたってのポイントもご紹介をいたします。
            </p>
          </TextBlock>
          <PrivateFuneralFlow />
          <SubHeading>家族葬のご利用事例</SubHeading>
          <TextBlock>
            <p>H.R.D Funeralが提供する家族葬のご利用事例をご紹介いたします。</p>
          </TextBlock>
          <FuneralExample
            title={'家族・親族を中心に、しめやかに葬儀を行いたい。'}
            listTitle={'お客様の状況・ご要望'}
            listElements={[
              '故人が高齢のため、お知らせする方が少ないので20名程度の葬儀を行いたい。',
              '通夜・葬儀・告別式は行いたいけれど、なるべく費用を抑えたい。',
              '遠方の親族の負担を少しでも軽減したい。'
            ]}
            services={[
              '寝台車',
              '御棺',
              'ご遺体保全',
              'セレモニースタッフ',
              '式場使用料',
              '祭壇',
              '遺影写真',
              '供花・供物',
              '返礼品',
              'お料理',
              '霊柩車'
            ]}
            amountOfPeopleText={
              <span>
                <span className='text-primary'>20名</span>
                <span className='text-black'>程度</span>
              </span>
            }
            funeralTypeText={'家族葬'}
            funeralStyleText={'すべての宗旨'}
          />
          <GoldWindow title='お客様から頂いた声' className='mt-[20px]'>
            <p>
              担当していただいたスタッフの方には大変お世話になりありがとうございました。
              亡き母に一人々が手紙を書くよう勧められ、最後に私が書いたのですが、
              みんなが接してきたストーリーを思い浮かべ感動しました。
              小さい家族葬でしたが大変大きな葬儀ができましたことを感謝しています。
            </p>
          </GoldWindow>
          <div className='w-full'>
            <LargeLinkButton
              className='mt-[20px] mx-auto'
              href={`${pages.halls.path}?ceremonyType=家族葬`}
            >
              家族葬が実施可能な式場を探す
            </LargeLinkButton>
          </div>
        </div>
      </div>
    </Tab>
  );
};

export default PrivateFuneralTab;
