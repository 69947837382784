import React from 'react';
import { Link } from 'react-router-dom';

const LargeLinkButton: React.FC<{
  className?: string;
  href: string;
  children: React.ReactNode;
}> = ({ className, href, children }) => {
  return (
    <div
      className={`${
        className ?? ''
      } h-[100px] bg-primary rounded-full drop-shadow-[0px_6px_0px_#00000029] relative px-[65px] w-fit`}
    >
      <Link to={href}>
        <div className='h-full flex justify-center items-center text-white [&>*]:text-white text-[22px] text-center'>
          {children}
        </div>
        <div className='flex items-center absolute right-[25px] top-0 h-full'>
          <img
            src='/images/arrow_white.png'
            alt='右に指す矢印'
            className='h-[22px] object-contain'
          />
        </div>
      </Link>
    </div>
  );
};

export default LargeLinkButton;
