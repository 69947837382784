import React from 'react';
import SubHeading from '../common/SubHeading';
import Banner from '../common/Banner';

const FlowElement: React.FC<{
  className?: string;
  title: string;
  index: number;
  src: string;
  alt: string;
  children: React.ReactNode;
  imageClass?: string;
}> = ({ className, title, index, src, alt, children, imageClass }) => {
  return (
    <div
      id={title}
      style={{ scrollMarginTop: '100px' }}
      className={`${
        className ?? ''
      } rounded-lg bg-white pc:grid grid-cols-[5fr_4fr] grid-rows-[100px_1fr] py-[30px] px-[6px] pc:px-[30px] relative`}
    >
      <Banner
        className='left-[10px] pc:left-[30px] -top-[7px] absolute'
        topText={'Flow'}
        bottomText={index.toString().padStart(2, '0')}
      />
      <div className='flex justify-center items-center col-span-2 order-1'>
        <SubHeading variant='h3'>{title}</SubHeading>
      </div>
      <img
        src={src}
        alt={alt}
        className={`object-cover pc:object-contain max-w-full rounded-lg order-3 aspect-[16/7] pc:aspect-auto mb-[20px] pc:mb-0 ${
          imageClass ?? ''
        }`}
      />
      <div className='pc:pr-[20px] text-[16px]/[30px] order-2 px-[10px] pc:px-[0px]'>
        {children}
      </div>
    </div>
  );
};

export default FlowElement;
