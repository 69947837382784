import React from 'react';
import { Link } from 'react-router-dom';
import { pages } from '../../config/pages';
import { useContactsModal } from '../../contexts/ContactsModalContext';
import PlanCard from '../common/PlanCard';

const Ceremonies: React.FC<{}> = () => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <>
      <div className='sp:hidden'>
        <div className='py-[70px] bg-[#F9F7F5]'>
          <div className='flex justify-center flex-col items-center mb-[40px]'>
            <p className='text-[#007477] font-serif font-bold text-[40px]'>
              H.R.D Funeralの葬儀をご紹介
            </p>
            <p className='text-[#95742E] font-serif font-bold text-[16px]'>
              PLAN
            </p>
          </div>
          <div className='flex flex-row justify-center'>
            <PlanCard
              planName='火葬式'
              planDescription='限られた人でシンプルに送りたい。'
              imageName='kasou.png'
              hasCremation
              className='mr-[19px]'
              path={pages.cremationFuneral.path}
              attendeeAmount={'1~5'}
            />
            <PlanCard
              planName='家族葬'
              planDescription='家族・親族を中心にしめやかに\n葬儀を行いたい。'
              imageName='kazoku.png'
              hasWake
              hasCondolences
              hasCremation
              className='mr-[19px]'
              path={pages.privateFuneral.path}
              attendeeAmount={'20'}
            />
            <PlanCard
              planName='一般葬'
              planDescription='友人知人を含め儀礼を重んじた葬儀で、\n故人を華やかに送りたい。'
              imageName='ippan.png'
              hasWake
              hasCondolences
              hasCremation
              path={pages.generalFuneral.path}
              attendeeAmount={'50'}
            />
          </div>
          <div className='flex justify-center mt-[50px] mb-[60px]'>
            <Link
              className='h-[106px] w-[403px] bg-primary rounded-[53px] flex justify-center items-center shadow-normal'
              to={pages.generalFuneral.path}
            >
              <p className='text-[#FFFFFF] font-bold text-[22px]'>
                H.R.D Funeralの葬儀へ
              </p>
              <img
                src='/images/arrow_white.png'
                alt='arrow_white'
                className='w-[11px] relative left-[54px]'
              />
            </Link>
          </div>
          <div className='flex justify-center'>
            <div className='mx-[20px] max-w-[1066px] h-[285px] rounded-[24px] border-[3px] border-primary bg-[#ffffff] flex flex-row'>
              <img
                src='/images/shasou.png'
                alt='shasou'
                className='h-full rounded-l-[21px]'
              />
              <div className='mx-[25px] my-[38px] justify-between flex flex-col'>
                <p className='font-serif font-bold text-[25px]'>
                  社葬・合同葬についてのご相談も承ります。
                </p>
                <p className='text-[16px] mb-[10px]'>
                  1000人規模の葬儀を実施した経験をもとに、
                  <br />
                  ご要望の葬儀を実現するため尽力いたします。まずはお気軽にご相談下さい。
                </p>
                <div
                  className='h-[70px] w-[305px] bg-primary rounded-[62px] flex justify-center items-center shadow-normal cursor-pointer'
                  onClick={handleClickContactsModal}
                >
                  <img
                    src='/images/phone.png'
                    alt='phone'
                    className='w-[28px] relative right-[10px]'
                  />
                  <p className='text-white font-bold text-[22px]'>
                    お電話での相談
                  </p>
                  <img
                    src='/images/arrow_white.png'
                    alt='arrow_white'
                    className='w-[11px] relative left-[30px]'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='pc:hidden bg-[#F9F7F5] py-[50px] w-full flex flex-col items-center'>
        <div className='mb-[70px] mx-[20px] max-w-[696px] flex flex-col items-center'>
          <p className='text-[30px] font-serif font-bold text-primary leading-loose text-center'>
            H.R.D Funeralの
            <br />
            葬儀をご紹介
          </p>
          <p className='text-[16px] font-serif font-bold text-[#95742E] mb-[10px]'>
            PLAN
          </p>
          <p className='text-[18px] font-serif font-semibold mb-[30px] leading-loose text-center'>
            いざ葬儀を執り行うとなると、やるべきことが多く、休む間もなく次々にやって来ます。
            <br />
            ここでは葬儀の形式、葬儀前の準備から式を終えるまでの葬儀の一連の流れをご紹介いたします。
          </p>
          <PlanCard
            planName='火葬式'
            planDescription='限られた人でシンプルに送りたい。'
            imageName='kasou.png'
            hasCremation
            className='mb-[20px]'
            path={pages.cremationFuneral.path}
            attendeeAmount={'1~5'}
          />
          <PlanCard
            planName='家族葬'
            planDescription='家族・親族を中心にしめやかに\n葬儀を行いたい。'
            imageName='kazoku.png'
            hasWake
            hasCondolences
            hasCremation
            className='mb-[20px]'
            path={pages.privateFuneral.path}
            attendeeAmount={'20'}
          />
          <PlanCard
            planName='一般葬'
            planDescription='友人知人を含め儀礼を重んじた葬儀で、\n故人を華やかに送りたい。'
            imageName='ippan.png'
            hasWake
            hasCondolences
            hasCremation
            path={pages.generalFuneral.path}
            attendeeAmount={'50'}
          />
          <Link
            to={pages.generalFuneral.path}
            className='w-[335px] h-[81px] rounded-[53px] bg-primary flex flex-row justify-center items-center shadow-normal my-[50px]'
          >
            <p className='text-[20px] text-white font-bold'>
              H.R.D Funeralの葬儀へ
            </p>
            <img
              src='/images/arrow_white.png'
              alt='arrow_white'
              className='w-[6px] relative left-[34px]'
            />
          </Link>
          <div className='h-[397px] border-[3px] rounded-[10px] border-primary'>
            <img
              src='/images/shasou_sp.png'
              alt='shasou_sp'
              className='w-full w-[696px] max-h-[98px] object-cover object-center rounded-t-[7px]'
            />
            <div className='w-full h-[293px] rounded-b-[7px] bg-white flex flex-col items-center py-[15px] justify-between'>
              <p className='font-serif font-bold text-[23px] text-center'>
                社葬・合同葬についての
                <br />
                ご相談も承ります。
              </p>
              <p className='text-[16px] text-center'>
                1000人規模の葬儀を実施した経験をもとに、ご要望の葬儀を実現するため
                尽力いたします。
                <br />
                まずはお気軽にご相談下さい。
              </p>
              <div
                className='w-[305px] h-[81px] rounded-[41px] bg-primary flex flex-row justify-center items-center shadow-normal cursor-pointer'
                onClick={handleClickContactsModal}
              >
                <img
                  src='/images/phone.png'
                  alt='phone'
                  className='w-[31px] mr-2'
                />
                <p className='text-[20px] text-white font-bold'>
                  お電話での相談
                </p>
                <img
                  src='/images/arrow_white.png'
                  alt='arrow_white'
                  className='w-[6px] relative left-[38px]'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ceremonies;
