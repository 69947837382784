import React from 'react';
import SpeechBubble from './SpeechBubble';

const Questions: React.FC<{}> = () => {
  return (
    <div className='px-[10px] mid:px-[20px] mt-[50px] pc:mt-[70px] mb-[25px] pc:mb-[50px]'>
      <div className='bg-[#DEF0EF] p-[30px] pb-[0px] mx-auto rounded-xl max-w-[1066px]'>
        <div className='flex justify-between'>
          <img
            src='/images/illustration/question_mark.png'
            alt='はてなマークのイラスト'
            className='hidden mid:block h-[50px] object-contain -rotate-45'
          />
          <img
            src='/images/illustration/question_heading.png'
            alt='「こんな疑問にお答えします」と書いてあるイラスト'
            className='h-[40px] object-contain mx-auto mb-[20px]'
          />
          <img
            src='/images/illustration/question_mark.png'
            alt='はてなマークのイラスト'
            className='hidden mid:block  h-[50px] object-contain '
          />
        </div>
        <div className='flex flex-wrap justify-center gap-[10px] items-center'>
          <SpeechBubble arrowClass='-left-[2px]'>
            事前に式場を確認したい
          </SpeechBubble>
          <SpeechBubble arrowClass='-left-[5px]'>
            式場の設備について知りたい
          </SpeechBubble>
          <SpeechBubble arrowClass='-left-[12px]'>
            宗教・宗派についてききたい
          </SpeechBubble>
          <SpeechBubble arrowClass='-left-[8px]'>
            マナーや基礎知識について知りたい
          </SpeechBubble>
          <SpeechBubble arrowClass='-left-[17px]'>
            葬儀後のサポート内容って？
          </SpeechBubble>
          <SpeechBubble arrowClass='-left-[15px]'>
            事前に準備すべきものは？
          </SpeechBubble>
        </div>
        <div className='mt-[10px] grid grid-rows-[min-content_1fr] mid:grid-rows-1 grid-cols-2 mid:grid-cols-[1fr_min-content_1fr] gap-[10px]'>
          <div className='col-span-2 mid:col-span-1 flex flex-col pc:flex-row items-center pc:items-start gap-[10px] mid:order-2'>
            <SpeechBubble arrowClass='left-[2px]'>
              葬儀って何から準備すればいいの？
            </SpeechBubble>
            <SpeechBubble>どのタイミングで問い合わせすべき？</SpeechBubble>
          </div>
          <div className='flex justify-center items-end mid:order-1'>
            <div className='relative'>
              <img
                src='/images/illustration/wondering_man.png'
                alt='考えている男の人のイラスト'
                className='w-full max-w-[100px] object-contain'
              />
              <img
                src='/images/illustration/question_mark.png'
                alt='はてなマークのイラスト'
                className='mid:hidden max-h-[50%] h-[60px] object-contain absolute -top-[10px] -right-[25px] -rotate-45'
              />
            </div>
          </div>
          <div className=' flex justify-center items-end mid:order-3'>
            <div className='relative'>
              <img
                src='/images/illustration/wondering_woman.png'
                alt='考えている女の人のイラスト'
                className='w-full max-w-[100px] object-contain'
              />
              <img
                src='/images/illustration/question_mark.png'
                alt='はてなマークのイラスト'
                className='mid:hidden max-h-[50%] h-[60px] object-contain absolute -top-[10px] -right-[25px] '
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
