import React from 'react';

const GoldWindow: React.FC<{
  className: string;
  title: string;
  children: React.ReactNode;
}> = ({ className, title, children }) => {
  return (
    <div
      className={`${
        className ?? ''
      } border-[1px] border-gold rounded-lg bg-white `}
    >
      <div className='border-b-[1px] border-gold py-[17px] w-full'>
        <p className='text-center text-gold font-bold'>{title}</p>
      </div>
      <div className='py-[20px] px-[15px] pc:px-[30px]'>{children}</div>
    </div>
  );
};

export default GoldWindow;
