import React, { useRef, useEffect } from 'react';

const SearchBox: React.FC<{
  prefectures: Array<string>;
  ceremonyTypes: Array<string>;
  selectedPrefecture: string | undefined;
  setSelectedPrefecture: (selectedPrefecture: string | undefined) => void;
  selectedCeremonyType: string | undefined;
  setSelectedCeremonyType: (selectedCeremonyType: string | undefined) => void;
}> = ({
  prefectures,
  ceremonyTypes,
  selectedPrefecture,
  setSelectedPrefecture,
  selectedCeremonyType,
  setSelectedCeremonyType
}) => {
  return (
    <div>
      <div className='bg-gold px-[20px] py-[15px] text-white grid pc:grid-cols-2 rounded-t-lg items-center'>
        <div className='text-center pc:text-left mb-[10px] pc:mb-0'>
          <img
            src='/images/magnifyingGlass.svg'
            alt='虫眼鏡のアイコン'
            className='inline-block h-[22px] w-[22px] object-cover mr-[7px]'
          />
          葬儀場を探す
        </div>
        <a href='#points'>
          <div className='text-center pc:text-right text-[12px]'>
            葬儀場を選ぶポイントはこちら{' '}
            <div className='inline-block'>
              <div className='bg-white rounded-full flex justify-center items-center h-[18px] pc:h-[22px] w-[18px] pc:w-[22px]'>
                <img
                  alt='右に指す矢印'
                  src='/images/arrow_gold.svg'
                  className='ml-[2px] h-[10px] w-[6px] object-contain'
                />
              </div>
            </div>
          </div>
        </a>
      </div>
      <div className='grid pc:grid-cols-2 gap-[20px] px-[30px] py-[20px] bg-white rounded-b-lg border-[1px] border-gold'>
        <Select
          elements={prefectures}
          selected={selectedPrefecture}
          setSelected={setSelectedPrefecture}
          title={'都道府県'}
        />
        <Select
          elements={ceremonyTypes}
          selected={selectedCeremonyType}
          setSelected={setSelectedCeremonyType}
          title={'葬儀種別'}
        />
      </div>
    </div>
  );
};

const Select: React.FC<{
  elements: Array<string>;
  title: string;
  selected: string | undefined;
  setSelected: (setSelected: string | undefined) => void;
}> = ({ elements, selected, setSelected, title }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = () => setIsOpen(!isOpen);
  const handleClickOption = (element: string | undefined) => () => {
    setSelected(element);
    setIsOpen(false);
  };

  const reference = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (reference.current && !reference.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [reference]);

  return (
    <div>
      <div className='mb-[8px]'>
        <div className='inline-block bg-gold rounded-lg h-[15px] w-[3px] mr-[8px]'></div>
        <span>{title}</span>
      </div>
      <div ref={reference}>
        <div
          onClick={handleClick}
          className={`grid grid-cols-[1fr_50px] bg-contrastBg border-[1px] ${
            isOpen ?
              ' border-gold pc:rounded-b-lg'
            : 'border-[#fff0] rounded-b-lg'
          } rounded-t-lg overflow-hidden cursor-pointer`}
        >
          <div className='py-[10px] px-[15px]'>
            {selected ?? <span className='opacity-60'>未選択</span>}
          </div>
          <div className='bg-gold flex justify-center items-center'>
            <img
              src='/images/arrow_white.png'
              alt=''
              className='rotate-90 h-[15px] w-[10px] object-cover'
            />
          </div>
        </div>
        {isOpen && (
          <div className='h-0 pc:h-fit w-full pc:w-[210px] relative pc:absolute pc:top-[50px] pc:overflow-hidden pc:rounded-lg pc:border-[1px] border-gold z-30 flex flex-col'>
            <Option option='未選択' onClick={handleClickOption(undefined)} />
            {elements.map((element, index) => (
              <Option
                key={index}
                option={element}
                onClick={handleClickOption(element)}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const Option: React.FC<{ option: string; onClick: () => void }> = ({
  option,
  onClick
}) => (
  <div
    onClick={onClick}
    className='w-full bg-contrastBg pc:border-x-[0px] border-x-[1px] last:pc:border-b-[0px] border-b-[1px] border-gold py-[7px] px-[15px] cursor-pointer'
  >
    {option}
  </div>
);

export default SearchBox;
