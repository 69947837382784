import React from 'react';
import Tab from '../Tab';
import SubHeading from '../../common/SubHeading';
import TextBlock from '../../common/TextBlock';
import FuneralExample from '../FuneralExample';
import GoldWindow from '../GoldWindow';
import LargeLinkButton from '../LargeLinkButton';
import { pages } from '../../../config/pages';
import GeneralFuneralFlow from './GeneralFuneralFlow';

const GeneralFuneralTab: React.FC<{}> = () => {
  return (
    <Tab funeralType='general'>
      <div className='bg-contrastBg w-full'>
        <div className=' py-[70px] max-w-[1066px] mx-auto px-[20px]'>
          <SubHeading>一般葬の流れとポイント</SubHeading>
          <TextBlock className='mb-[30px]'>
            <p>
              一般葬の流れの一例とともに、準備や対応にあたってのポイントもご紹介をいたします。
            </p>
          </TextBlock>
          <GeneralFuneralFlow />
          <SubHeading>一般葬のご利用事例</SubHeading>
          <TextBlock>
            <p>H.R.D Funeralが提供する一般葬のご利用事例をご紹介いたします。</p>
          </TextBlock>
          <FuneralExample
            title={
              '友人知人を含め儀礼を重んじた葬儀で、故人を華やかに送りたい。'
            }
            listTitle={'お客様の状況・ご要望'}
            listElements={[
              '故人の交友関係が広く、たくさんの方がご会葬できる葬儀にしたい。',
              'ご会葬された方をきちんともてなし、儀礼を重んじた葬儀にしたい。',
              '友人知人を含め、皆で故人を華やかに送りたい。'
            ]}
            services={[
              '寝台車',
              '御棺',
              'ご遺体保全',
              'セレモニースタッフ',
              '式場使用料',
              '祭壇',
              '遺影写真',
              '供花・供物',
              '返礼品',
              'お料理',
              '霊柩車'
            ]}
            amountOfPeopleText={
              <span>
                <span className='text-primary'>50名</span>
                <span className='text-black'>程度～</span>
                <span className='text-black text-[14px]'>
                  （親族20名/参列者30名）
                </span>
              </span>
            }
            funeralTypeText={'一般葬'}
            funeralStyleText={'すべての宗旨'}
          />
          <GoldWindow title='お客様から頂いた声' className='mt-[20px]'>
            <p>
              昨年の母の葬儀に引き続き、今回の父の葬儀も、
              ほんとうに素晴らしかったです。担当して下さった方には（他スタッフの方々にも）、
              親身になって、いろいろとお世話して頂きまして、母もそうだと思いますが、
              父もとても喜んでいると思います。こころから感謝いたします。ありがとうございました。
            </p>
          </GoldWindow>
          <div className='w-full'>
            <LargeLinkButton
              className='mt-[20px] mx-auto'
              href={`${pages.halls.path}?ceremonyType=一般葬`}
            >
              一般葬が実施可能な式場を探す
            </LargeLinkButton>
          </div>
        </div>
      </div>
    </Tab>
  );
};

export default GeneralFuneralTab;
