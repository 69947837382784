import { Prefecture, prefectureOrder } from './constants';

export type HallType = {
  id: number;
  prefecture: Prefecture;
  hallName: string;
  address: string;
  phoneNumber: string;
  url: string;
  offersPrivateFuneral: boolean;
  offersOneDayFuneral: boolean;
  offersGeneralFuneral: boolean;
  offersLargeScaleFuneral: boolean;
  offersCremation: boolean;
  hasParking: boolean;
  isNearStation: boolean;
  isNearCrematorium: boolean;
  hasRestZone: boolean;
  hasMorgue: boolean;
  isBarrierFree: boolean;
  hasAccommodation: boolean;
  hasMovie: boolean;
  hasKitchenFacility: boolean;
};

export const getSpecialtiesArray = (hallData: HallType): Array<string> =>
  Object.keys(specialtyMap)
    .filter((key) => hallData[key as keyof HallType])
    .map((key) => specialtyMap[key as keyof typeof specialtyMap]);

export const specialtyMap: Record<
  keyof Omit<
    HallType,
    | 'id'
    | 'prefecture'
    | 'hallName'
    | 'address'
    | 'phoneNumber'
    | 'url'
    | 'offersPrivateFuneral'
    | 'offersGeneralFuneral'
    | 'offersCremation'
  >,
  string
> = {
  offersOneDayFuneral: '一日葬',
  offersLargeScaleFuneral: '大型葬・社葬',
  hasParking: '駐車場有',
  isNearStation: '駅に近い',
  isNearCrematorium: '火葬場に近い',
  hasRestZone: '仮眠施設有',
  hasMorgue: '安置施設有',
  isBarrierFree: 'バリアフリー',
  hasAccommodation: '付き添い（宿泊）可能',
  hasMovie: 'メモリアル映像',
  hasKitchenFacility: '厨房設備'
} as const;

export const ceremonyTypesMap: Record<
  string,
  keyof Pick<
    HallType,
    'offersPrivateFuneral' | 'offersGeneralFuneral' | 'offersCremation'
  >
> = {
  家族葬: 'offersPrivateFuneral',
  一般葬: 'offersGeneralFuneral',
  火葬式: 'offersCremation'
} as const;

export const ceremonyTypes = Object.keys(ceremonyTypesMap);

export const sortHalls = (hallA: HallType, hallB: HallType) =>
  prefectureOrder[hallA.prefecture] - prefectureOrder[hallB.prefecture];
