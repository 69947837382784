import React from 'react';
import SubHeading from '../common/SubHeading';

const FlowHeader: React.FC<{
  backgroundClass: string;
  steps: Array<string>;
  title: string;
}> = ({ steps, backgroundClass, title }) => {
  return (
    <div className={`rounded-lg w-full pt-[30px] bg-white overflow-hidden`}>
      <SubHeading variant='h3'>{title}</SubHeading>
      <div
        className={`
        flex justify-center items-center flex-col pc:flex-row gap-[12px] pc:gap-[20px] pb-[30px] pc:h-[260px] bg-cover bg-no-repeat   
        ${backgroundClass}`}
      >
        {steps.map((title, index) => (
          <FlowHeaderStep key={index} index={index} title={title} />
        ))}
      </div>
    </div>
  );
};

const FlowHeaderStep: React.FC<{
  index: number;
  title: string;
}> = ({ index, title }) => (
  <a
    href={`#${title.replace(/\s/g, '')}`}
    className='max-w-[200px] w-full  pc:h-full pc:max-w-[45px]'
  >
    <div className='max-h-[45px] pc:max-h-none pc:h-full rounded-tr-lg pc:rounded-tr-3xl rounded-tl-3xl rounded-bl-3xl pc:rounded-bl-lg rounded-br-lg bg-primary p-[13px] flex items-center pc:flex-col relative'>
      <div className='text-white text-[20px] pc:mb-[5px] mr-[10px] pc:mr-0 font-stix'>
        {(index + 1).toString().padStart(2, '0')}
      </div>
      <div className='h-[10px] pc:h-[1px] w-[2px] pc:w-[10px] bg-white/50 pc:mb-[10px] mr-[10px] pc:mr-0'></div>
      <div className='text-center text-white text-[18px]/[22px]'>{title}</div>
      <img
        src='/images/arrow_white.png'
        alt='下に指す矢印'
        className='w-[7px] rotate-90 absolute pc:bottom-[10px] right-[15px] pc:right-[unset]'
      />
    </div>
  </a>
);

export default FlowHeader;
