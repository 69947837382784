import React from 'react';
import SubHeading from '../common/SubHeading';
import InfoCard from '../common/InfoCard';
import InfoCardContainer from '../common/InfoCardContainer';

const Information: React.FC<{}> = () => {
  return (
    <section className='bg-white py-[50px] pc:py-[70px] px-[10px] mid:px-[20px]'>
      <div className='max-w-[700px] pc:max-w-[1066px] mx-auto'>
        <div className='mx-[150px] sp:mx-auto bg-[#F9F7F5] rounded-lg p-[30px] pc:p-[40px]'>
          <SubHeading>ご危篤を告げられた時</SubHeading>
          <div className='mt-[30px] text-center font-serif text-[18px] pc:text-[20px]'>
            <p>ご不安なことがあればH.R.D Funeralへご相談ください。</p>
            <p>
              心配に思っていることや、疑問点をはじめサポートさせていただきます。
            </p>
            <p>
              今は故人様との最後の時間として、ご親族や大切な方々と共に、最期の時を大切にお過ごしください。
            </p>
          </div>
        </div>
        <div className='mt-[25px] pc:my-[40px]'>
          <SubHeading>ご逝去時</SubHeading>
        </div>
        <InfoCardContainer>
          <InfoCard
            src={'/images/urgent/phone_user.png'}
            alt={'スマートフォンを操作している女性の写真。'}
            heading={'まずはお電話ください。'}
            label={<InfoCardLabel>01</InfoCardLabel>}
          >
            <p>もしもの時は、すぐにお電話ください。</p>
            <p>
              深夜や早朝問わず、24時間365日専門スタッフがご対応いたします。{' '}
            </p>
            <p>
              「進め方がわからない」場合も、
              こちらから必要な事項のご質問をしながら、
              葬儀の段取りを進めさせていただきます。
              葬儀費用についてもご予算にあわせてご案内いたしますので、
              ご安心ください。
            </p>
          </InfoCard>
          <InfoCard
            src={'images/urgent/driver.png'}
            alt={'車を運転する人のハンドルの写真。'}
            heading={'お迎えに参ります。'}
            label={<InfoCardLabel>02</InfoCardLabel>}
          >
            <p>
              お迎えに上がった際に、
              ご安置先やお葬式を行う式場などについて打合せます。{' '}
            </p>
            <p>
              病院などからご遺体を引取り、
              ご自宅や安置施設（斎場・葬儀会館）などにお運びします。
              搬送専用の車でお迎えにあがりますので、
              ご安置先の希望や要望がございましたらお伝えください。
            </p>
          </InfoCard>
          <InfoCard
            src={'images/urgent/consultation.png'}
            alt={'相談する二人の写真。'}
            heading={'ご要望や葬儀内容について、 打合せを行います。'}
            label={<InfoCardLabel>03</InfoCardLabel>}
          >
            <p>
              ご希望の日程や規模感をはじめ、
              お客様の要望に合わせて最適な式場・斎場をご提案いたします。{' '}
            </p>
            <p>
              地域密着の葬儀社ならではの提供可能なサービスと、
              ご要望に併せた明瞭なお見積りをご提示いたします。{' '}
            </p>
            <p>
              私たちは、大切な人との最期のお別れをまごころをこめてお手伝いいたします。
            </p>
          </InfoCard>
        </InfoCardContainer>
      </div>
    </section>
  );
};

const InfoCardLabel: React.FC<{ children: React.ReactNode }> = ({
  children
}) => (
  <span className='font-serif font-bold text-[36px] text-primary'>
    {children}
  </span>
);

export default Information;
