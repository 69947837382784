import React from 'react';
import Flow from '../Flow';
import FlowPoint from '../FlowPoint';
import FlowSpeech from '../FlowSpeech';
import FlowElement from '../FlowElement';
import TitledList from '../TitledList';
import FlowFinalElement from '../FlowFinalElement';
import FlowHeader from '../FlowHeader';

const GeneralFuneralFlow: React.FC<{}> = () => {
  return (
    <div className='mb-[70px]'>
      <FlowHeader
        title={'一般葬の流れ'}
        backgroundClass={
          "bg-[linear-gradient(180deg,rgba(255,255,255,1)_0%,rgba(255,255,255,0)_33%,rgba(255,255,255,0)_100%),url('/public/images/funerals/general_ceremony_sp.png')] " +
          "pc:bg-[linear-gradient(180deg,rgba(255,255,255,1)_0%,rgba(255,255,255,0)_33%,rgba(255,255,255,0)_100%),url('/public/images/funerals/general_ceremony.png')]"
        }
        steps={[
          '搬送',
          'ご安置',
          '納棺',
          '通夜式',
          '葬儀 ・ 告別式',
          '火葬',
          '精進落とし'
        ]}
      />
      <Flow>
        <FlowSpeech>
          <p>
            一般葬は、家族や親族だけでなく、会社の同僚や友人、知人などを招いて、
            故人を偲びます。故人と最期の夜を過ごす「通夜」からはじまり、
            翌日に「告別式」、告別式のあと「火葬」を行うのが一般的な流れです。
          </p>
          <p>
            一般葬は、沢山の関係者に見守られながら、伝統的なお別れができるのがメリットです。
          </p>
        </FlowSpeech>
        <FlowElement
          title='搬送'
          index={1}
          src={'/images/funerals/driver.png'}
          alt={'葬儀車を運転する人のハンドルの写真'}
        >
          <p>
            ご逝去後、看護師により故人様のお体を拭き清めるエンゼルケアを行います。{' '}
          </p>
          <p className='mb-[1.5em]'>
            その後、ご安置する場所（ご自宅・安置施設など）へ故人様を搬送いたします。{' '}
          </p>
          <p>
            事業としてご遺体搬送を行うには、貨物自動車運送事業法に基づき、国土交通省から「一般貨物自動車運送事業」の許可を受けた事業者である必要がございます。{' '}
          </p>
          <p>
            ご自宅へ安置する場合、自家用車で行うことは法律上問題ございませんが、
            専用のストレッチャーを使用することでご遺体への負担が軽減されますので、
            葬儀社へ寝台車による搬送を依頼されたほうがよいでしょう。
            葬儀社へ搬送を依頼した場合、枕付きの防水シーツ、掛け布団が用意されているのが一般的です。{' '}
          </p>
          <p>
            葬儀社の霊安室へ安置する場合は、霊安室の空き状況も含め葬儀社への連絡が必要です。
          </p>
        </FlowElement>

        <FlowPoint>
          <p>
            長距離のご遺体搬送料金は、規程の搬送料金に加えて、高速・有料道路通行料金、ご遺体保全のためのドライアイス、交代要員などが必要な場合の人件費等がかかります。
          </p>
          <p>
            搬送業者にそのまま葬儀まで依頼をしている場合は、後日葬儀後にまとめてお支払いとなりますが、依頼している葬儀式場へ別の搬送業者を利用される場合は、ご遺体の安置後に別途お支払いをしていただくのが一般的です。
          </p>
        </FlowPoint>
        <FlowPoint>
          <p>
            搬送から実際に葬儀を執り行うまでの準備の関係から、ご自身（喪主）の情報と、故人様の情報、搬送先、ご遺体の状況、宗旨･宗派について、伝えることができるよう準備をしておきましょう。
          </p>
        </FlowPoint>
        <FlowSpeech showPhoneModalButton={true}>
          <p>
            ご遺体の搬送のお電話の際に、ご希望の葬儀形式などをお伺いすることもございます。
            もし希望の葬儀形式などが決まっている場合は、対応が可能かも含めて問い合わせと同時に聞いておきましょう。
          </p>
        </FlowSpeech>
        <FlowElement
          title='ご安置'
          index={2}
          src={'/images/funerals/rest.png'}
          alt={'葬儀社員の写真'}
        >
          <p>
            通夜式や葬儀式までの一定期間、ご遺体を保管することを安置と言います。
          </p>
          <p>
            ご自宅の場合、北向き又は西向きに安置し、ドライアイスなどを使用してご遺体を保全いたします。
          </p>
          <p className='mb-[1.5em]'>
            葬儀社へ依頼するとドライアイスのほか、枕飾り（焼香用具）の手配もできます。
          </p>
          <p>
            近年では住宅事情などにより葬儀社の霊安室を利用される方が増えております。
            霊安室の場合、ご遺体専用の保冷庫を完備している所も多く、
            ご遺体を凍結することなく安置することが可能です。
          </p>
          <p>
            安置後は連絡が必要な親戚や友人・知人に安置場所をお知らせします。
          </p>
          <TitledList
            className='mt-[20px]'
            variant={'horizontal'}
            title={'【安置施設の主な種類】'}
            elements={['ご自宅', '葬儀社・斎場', '民間の安置施設']}
          />
        </FlowElement>
        <FlowSpeech>
          <p>ご自宅又は、当社施設などご希望の場所へご安置いたします。</p>
        </FlowSpeech>
        <FlowPoint>
          <p>
            ご葬儀を家族だけで済ませた後に、お知らせしなかったご親戚や親しい方から「どうして呼んでくれなかったの」「親しくしていたのにお別れができなかった」等のお声が寄せられ、思わぬ事態に発展してしまうこともあります。
          </p>
          <p>
            また、会葬者の総数が少ないために、香典収入が少なくなります。その結果、葬儀費用を香典収入だけではまかなえなくなり、持ち出し分が多くなってしまうことがあります。
          </p>
        </FlowPoint>
        <FlowElement
          title='納棺'
          index={3}
          src={'/images/funerals/coffin.png'}
          alt={'納棺の写真'}
        >
          <p>
            納棺は、故人が穢れのないからだで旅立てるように支度を整える儀式です。
          </p>
          <p className='mb-[1.5em]'>
            故人の安らかな旅路を願うだけでなく、遺族が一緒にゆっくりと過ごせる最後の時間でもあります。
          </p>
          <p>
            納棺に立ち会われるのは故人の配偶者、子供、兄弟姉妹が一般的で、故人様のお体を逆さ水（水にお湯を足したぬるま湯）で清拭し、旅支度として手甲や脚絆・足袋・経帷子などを身に着けて差し上げます。
          </p>
          <p>
            その後、立ち会われているご親族様の手により故人をお棺へとお納めし、故人の愛用品など「副葬品」も一緒にお納めします。
          </p>
        </FlowElement>
        <FlowPoint>
          <p>
            近年では「納棺師」によるエンゼルメイク（死化粧）や、故人らしい服に着せ替えるなど、出来るだけ生前のお姿に整えてから納棺をすることが一般的となりました。
          </p>
          <p>
            故人様の愛用品も一緒にお納めするため、火葬場の規定等で棺の中へ入れられないものなどもございますので、事前に相談をしておきましょう。
          </p>
        </FlowPoint>
        <FlowElement
          title='通夜式'
          index={4}
          src={'/images/funerals/wake_ceremony.jpg'}
          alt={'通夜の写真'}
        >
          <p className='mb-[1.5em]'>
            お通夜は本来、遺族や近親者、親しい友人など、故人と関わりの深かった人たちが集まって最後の別れを惜しみ、故人の霊と遺族を慰めるためのものです。
          </p>
          <p>
            特に親しい関係でなければお通夜には出席せず、翌日の葬儀・告別式に参列するのが本来ですが、最近は昼間に行われる葬儀・告別式よりも、出席しやすい時間帯に行われるお通夜の方に出席する人も多くなっています。
          </p>
        </FlowElement>
        <FlowSpeech>
          <p>
            会葬者からすると、通夜式を設けることで、忙しく時間調整が難しい方でも勤務時間外に会葬に参加出来るメリットがあります。
          </p>
        </FlowSpeech>
        <FlowElement
          title='葬儀・告別式'
          index={5}
          src={'/images/funerals/funeral_service.jpg'}
          alt={'葬儀の写真'}
        >
          <p>
            現代では葬儀・告別式と一連の流れで執り行いますが、本来は、「葬儀式」と「告別式」の２つに分かれております。
          </p>
          <p>
            葬儀式とは宗教的な儀礼儀式であり、
            一般的な仏式の葬儀を例に挙げると、
            ご遺族・ご親族同席のもと僧侶による読経、
            引導作法、戒名授与など焼香前までの儀式とされております。
          </p>
          <p>
            一方で告別式は社会的な別れの儀式で、
            友人や知人など故人とゆかりのあった方々が最後のお別れを告げるための儀式と位置付けられており、
            弔辞（お別れの言葉）、一般焼香（献花）、弔電奉読、喪主挨拶などがあげられます。
          </p>
          <p>
            現代では「葬儀式」と「告別式」は一つの儀式として扱われることが多くなり「葬儀・告別式」と称されております。
          </p>
        </FlowElement>

        <FlowElement
          title='火葬'
          index={6}
          src={'/images/funerals/cremation_ceremony.png'}
          alt={'骨壺の写真'}
        >
          <TitledList
            className='mb-[20px]'
            variant={'horizontal'}
            title={'【火葬の流れ】'}
            elements={[
              '火葬許可証を渡す',
              '火葬前の儀式（宗教儀礼）',
              '火葬・待機',
              '収骨'
            ]}
          />
          <p>火葬式では、基本的に親族である方が立ち合いをされます。</p>
          <p className='mb-[1.5em]'>
            所要時間は1～2時間程度で、一般的な火葬場の営業時間が朝の9時～16時頃までとなります（火葬場によって変動します）。
          </p>
          <p>
            火葬終了後、収骨室にて「骨上げ」を行います。ひとつの遺骨を2人1組にて竹製と木製の箸を一本ずつ使って拾い、
            骨壷に納めてまいります。喪主から順番に血縁の深い順に行い、
            現世から来世への橋渡しをするという意味合いがあると言われております。
          </p>
          <p>
            収骨後、提出した火葬許可証は「火葬執行」の印が押され、
            「埋葬許可証」となります。埋葬時に必要な書類ですので、
            骨壺と一緒にしておくなど紛失しないようにしましょう。
          </p>
        </FlowElement>
        <FlowSpeech>
          <p>
            担当スタッフが火葬場の予約・役所等への火葬手続きを代行いたします。{' '}
          </p>
          <p>不明点やご不安なことがあれば、担当者へお申し付けください。</p>
        </FlowSpeech>
        <FlowElement
          title={'精進落とし'}
          index={7}
          src={'/images/funerals/after_ceremony.png'}
          alt={'食事を食べる人の写真'}
        >
          <p className='mb-[1.5em]'>
            精進落としとは、一般的に初七日法要のあとに料理を振る舞い、
            宗教者や参列者をもてなすことを言います。
            古くは、49日目の忌明けまで魚や肉などの生臭物を絶ち、
            精進料理を食べていた遺族が、通常の食事をし日常生活に戻ることを言いました。{' '}
          </p>
          <p>
            しかし近年では、初七日法要のあとに昔ながらの精進料理ではなく、
            魚や肉の入った会席料理（精進落とし料理）を振る舞います。
            そして宗教者や参列者、世話役など、葬儀でお世話になった方々をもてなす意味合いがあります。
          </p>
        </FlowElement>
        <FlowSpeech>
          <p>
            精進落としには、遺族の苦労をねぎらい、参列者に感謝を示す側面があります。華やかなメニューを出すのが通例ですが、重要なのは参列者の人数や年齢層にあわせた料理にすることです。
          </p>
          <p>
            人数がある程度決まっているのであれば、参列者1人1人にお膳を用意するのがおすすめ。参加を辞退されても、お膳ならお持ち帰り料理として包んで渡しやすいので便利です。
          </p>
          <p>
            また、故人の知人・友人など思わぬ方がお見えになる場合もありますので、余裕を持った数量を注文しておくと良いでしょう。
          </p>
        </FlowSpeech>
        <FlowSpeech>
          <p>
            病院で亡くなった場合・自宅で亡くなった場合と、
            様々なパターンが考えられる中で、問い合わせを実施したり比較をしたりと、
            対応する必要がある事項が多いため、事前に希望の条件を相談しておくなど、
            葬儀社の選定なども済ませておくことをお勧めします。
          </p>
        </FlowSpeech>
        <FlowFinalElement>
          <p>ここまでで一般葬の流れは以上となります。 </p>
          <p>
            式が終わった後もお墓や相続・遺品整理など、対応すべき事項は多くあります。
          </p>
          <p>
            当日の流れは勿論のこと、葬儀が終わった後のサポートについてもご相談可能ですので、
            お気軽にお問い合わせください。
          </p>
          <p>
            希望内容によって最適なご提案をできるよう、スタッフ一同誠心誠意対応をさせていただきます。
          </p>
        </FlowFinalElement>
      </Flow>
    </div>
  );
};

export default GeneralFuneralFlow;
