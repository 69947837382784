import React from 'react';

const InfoCard: React.FC<{
  src: string;
  alt: string;
  heading: string;
  imagePosition?: 'top' | 'center' | 'bottom';
  label?: React.ReactNode;
  children: React.ReactNode;
}> = ({ src, alt, heading, imagePosition, children, label }) => (
  <div className='max-w-[700px] font-sans'>
    <div className='relative'>
      <img
        src={src}
        alt={alt}
        className={`w-full rounded-xl max-h-[160px] object-cover ${
          imagePosition ?
            imagePosition === 'top' ? 'object-top'
            : imagePosition === 'center' ? 'object-center'
            : imagePosition === 'bottom' ? 'object-bottom'
            : ''
          : ''
        }`}
      />
      <div className='h-[37px] relative'>
        <div className='bg-white rounded-full flex justify-center items-center h-[77px] w-[77px] mx-auto relative -top-[36px]'>
          {label}
        </div>
      </div>
    </div>
    <h3 className='text-[20px] text-primary text-center font-bold mt-[4px] mb-[10px]'>
      {heading}
    </h3>
    <div className='text[16px] leading-[30px] tracking-[0.32px]'>
      {children}
    </div>
  </div>
);

export default InfoCard;
