import { Link } from 'react-router-dom';
import { pages } from '../../config/pages';
import Arguments from '../Strengths/Arguments';

const Services = () => {
  return (
    <>
      <div className='flex flex-col items-center'>
        <div className='mb-[70px] max-w-[696px] pc:max-w-none'>
          <Arguments hasTagLine={true} />
          <div className='mx-[20px] flex flex-col items-center'>
            <Link
              to={pages.strengths.path}
              className=' w-[335px] pc:w-[403px] h-[81px] pc:h-[106px] rounded-[53px] bg-primary flex flex-row justify-center items-center shadow-normal'
            >
              <p className='text-[20px] text-white font-bold'>
                H.R.D Funeralの特長へ
              </p>
              <img
                src='/images/arrow_white.png'
                alt='arrow_white'
                className='w-[6px] relative left-[34px]'
              />
            </Link>
            <div className='pc:flex mt-[70px] pc:mt-[100px] pc:justify-center pc:mb-[100px]'>
              <img
                src='/images/flower.png'
                alt='flower'
                className='hidden pc:block h-[350px] object-cover top-[2420px] absolute'
              />
              <div className='border-[1px] pc:border-[3px] border-[#AC5F91] rounded-[10px] pc:rounded-[30px] w-full z-10 bg-white'>
                <div className='h-[56px] pc:h-[80px] rounded-t-[7px] pc:rounded-t-[20px] bg-[#AC5F91] p-[20px] flex justify-center items-center'>
                  <p className='text-[20px] pc:text-[30px] text-white font-serif font-bold'>
                    オンライン葬儀サービス受付中
                  </p>
                </div>
                <div className='grid p-[20px] pc:grid-cols-[1fr_1fr]'>
                  <img
                    src='/images/okuyami_logo.png'
                    alt='okuyami_logo'
                    className='h-[35px] pc:h-[70px] mb-[20px] w-full object-contain object-center pc:order-1 pc:col-span-2'
                  />
                  <p className='text-[18px] pc:text-[22px] text-[#AC5F91] font-serif font-bold pc:font-black text-center pc:text-left mb-[16px] pc:order-2'>
                    <span className='pc:text-black'>
                      葬儀への直接のご参加が難しい方へ、
                    </span>
                    <br />
                    オンラインでお別れの場を
                    <br className='pc:hidden' />
                    ご用意いたします。
                  </p>
                  <img
                    src='/images/demo_page_sp.png'
                    alt='demo_page'
                    className='pc:hidden h-[149px] mb-[15px] w-full object-contain object-center'
                  />
                  <img
                    src='/images/demo_page.png'
                    alt='demo_page'
                    className='hidden pc:block h-[149px] mb-[15px] w-full object-contain object-center pc:order-3 pc:row-span-2 pc:h-[300px]'
                  />
                  <div className='pc:order-4 pc:pr-[20px]'>
                    <div className='w-full h-[1px] bg-[#333333] mb-[10px]' />
                    <p className='text-[21px] font-serif font-bold pc:font-black text-center'>
                      オンライン葬儀でできること
                    </p>
                    <div className='w-full h-[1px] bg-[#333333] mt-[10px] mb-[15px]' />
                    <div className='flex justify-center'>
                      <p className='text-[14px] pc:text-[16px] font-serif font-bold leading-loose'>
                        ・オンライン式場内で故人様とのお別れ（焼香・拝礼）ができます。
                        <br />
                        ・訃報案内をWEBでお送りいただけます。
                        <br />
                        ・会葬者から追悼メッセージや想い出の写真を送ることが出来ます。
                        <br />
                        ・供物や香典をオンライン上で決済することができます。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Services;
