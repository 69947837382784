import React from 'react';

const TitledList: React.FC<{
  className?: string;
  variant: 'vertical' | 'horizontal';
  title: string;
  elements: Array<string>;
}> = ({ className, variant, title, elements }) => {
  return (
    <div
      className={`${className ?? ''}  ${
        variant === 'vertical' ? '' : 'pc:grid grid-cols-2'
      } p-[15px] rounded-lg bg-contrastBg`}
    >
      <div
        className={`flex justify-center items-center font-bold ${
          variant === 'vertical' ?
            'border-b-[1px] p-[10px] mb-[10px]'
          : 'border-b-[1px] p-[10px] pc:p-0 mb-[10px] pc:mb-0 pc:border-r-[1px] pc:border-b-0'
        }  border-[#AAAAAA]`}
      >
        {title}
      </div>
      <div className='pc:pl-[33px]'>
        <ul>
          {elements.map((element, index) => (
            <li
              key={index}
              className={`${variant === 'vertical' ? 'my-[5px]' : ''}`}
            >
              ・{element}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TitledList;
