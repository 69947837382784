import { EventType } from '../components/Events/EventBox';

export const eventList = [
  {
    pageId: 0,
    title: 'JA兵庫西JAやすらぎホール佐用秋のふれあいフェスタ',
    metaTitle:
      '【参加無料】秋のふれあいフェスタ 餅まきや出店などお子様と一緒に楽しめます',
    eventDate: '2024年10月14日(月)9:00~13:00',
    location: {
      name: 'JAやすらぎホール佐用',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/509',
      googleMap: 'https://maps.app.goo.gl/yHb5AS6AHSVoBScX8'
    },
    contact: {
      name: 'JA兵庫西葬祭センター',
      tel: '0120-079-632'
    },
    reception: `お楽しみ抽選会にご参加いただく場合は、受付が必要となります。
2024年10月14日(月)9:00~11:00 先着200名様限り`,
    fee: '無料',
    imgUrl: ['/images/events/autumnfes.png', '/images/events/autumnfes2.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `様々なイベントが目白押しの秋のふれあいフェスタを開催いたします。 
必ず景品がもらえるお楽しみ抽選会も先着200名様限り実施いたします。`,
      schedule: [
        {
          name: '9:00~',
          description: '抽選会・似顔絵・朝市生花 ・キッズコーナー・キッチンカー'
        },
        {
          name: '9:45~9:45',
          description: '宍粟和太鼓アーツクラブ・和太鼓「弾紅」（ダンク）'
        },
        {
          name: '10:30~11:20',
          description: '佐用保育園：ダンス ・ジミーハウス：キッズダンス'
        },
        {
          name: '11:30~12:20',
          description: 'ビンゴゲーム'
        },
        {
          name: '12:30~',
          description: '餅まき'
        }
      ]
    }
  },
  {
    pageId: 1,
    title: 'シティホール豊田 Autumnフェスタ・人形供養祭',
    metaTitle:
      '【参加無料】Autumnフェスタ 人形供養祭やライブなどイベント盛りだくさん',
    eventDate: '2024年10月20日(日)9:00~12:30',
    location: {
      name: 'シティホール豊田',
      companyUrl: 'https://www.city-hall-toyoda.jp/',
      googleMap: 'https://maps.app.goo.gl/mD5jEYmWEDbSBkGS9'
    },
    contact: { name: 'シティホール豊田', tel: '0120-136-344' },
    reception: `人形供養祭へご参加いただく場合は、受付が必要となります。
2024年10月20日(日)9:00~11:00`,
    fee: `イベント参加は、無料となります。
人形供養をする方は、以下の費用となります。
供養料（シティ友の会会員様） 無料
供養料（一般のお客様） 20体まで:2,500円 （税込み） 20体以上:1体につき200円(税込み)`,
    imgUrl: ['/images/events/cityhall1.png', '/images/events/cityhall2.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `人形供養祭を開催するにあたり、豪華なイベントを交えたフェスタを開催いたします。
メインイベントとしては、Mr.シャチホコ ライブが開催されます。
また、来場いただいた方全員にもれなくガラポン抽選会にもご参加いただけますので、お気軽にご参加をお待ちしております。`,
      schedule: [
        { name: '9:00~', description: '人形供養祭の受付開始' },
        { name: '9:45~10:15', description: 'フルネス ライブ' },
        { name: '10:30~11:00', description: 'Mr.シャチホコ ライブ' },
        { name: '11:00', description: '人形供養祭の受付終了' },
        { name: '11:15~11:45', description: '人形供養 読経' },
        { name: '12:00~', description: 'お餅投げ＆お菓子投げ' },
        { name: '12:30', description: 'イベント終了' }
      ]
    }
  },
  {
    pageId: 2,
    title: 'JAやすらぎホール津山感謝祭/人形供養祭',
    metaTitle:
      '【参加無料】JAやすらぎホール津山感謝祭 無料人形供養や他イベントあり',
    eventDate: '2024年10月20日(日)9:00~12:00',
    location: {
      name: 'JAやすらぎホール津山',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/380',
      googleMap: 'https://maps.app.goo.gl/EPRvMqrZNSZrmV437'
    },
    contact: {
      name: '津山メモリアルセンター',
      tel: '0120-831-983'
    },
    reception: `人形供養祭へご参加いただく場合は、受付が必要となります。
2024年10月20日(日)9:00~10:20`,
    fee: '無料',
    imgUrl: ['/images/events/haradaEvent.png'],
    finished: true,
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `大切にしていた人形にありがとうの気持ちをこめた人形供養祭や華やかな衣装を身にまとい、
優雅に踊るハワイアンフラなどのメインイベントに加えてメモリアル写真撮影会も開催いたします。`,
      schedule: [
        { name: '9:00~10:20', description: '人形供養祭の受付時間' },
        { name: '10:30~11:00', description: '供養祭' },
        { name: '11:15~11:45', description: 'ハワイアンフラ' },
        { name: '11:45', description: '餅投げ・フィナーレ' }
      ]
    }
  },
  {
    pageId: 3,
    title: 'JA祭典なめがた潮来ホール人形供養祭・餅まき・友引市',
    metaTitle:
      '【参加無料】JA祭典なめがた潮来ホール人形供養祭 豪華景品がゲットできる餅まきなど',
    eventDate: '2024年11月17日(日)9:00~13:00',
    location: {
      name: 'JA祭典なめがた潮来ホール',
      companyUrl: 'https://sougi.bestnet.ne.jp/itako/',
      googleMap: 'https://maps.app.goo.gl/c5XqFaUyPxF77C5s8'
    },
    contact: {
      name: 'JA祭典なめがた潮来ホール',
      tel: '0120-993-794'
    },
    reception: `以下の日時で人形をお預かりいたします。
①2024年11月9日(土)、10日(日)、16日(土)いずれの日も14:00~16:00
②2024年11月17日(日) 9:00~10:00`,
    fee: `イベント参加は無料となります。
人形供養をする方は、以下の費用となります。
供養料（メモリアル会員様） 無料
供養料(一般のお客様)10体まで:500円(税込み)11体以上:1体につき1,000円(税込み)`,
    imgUrl: ['/images/events/2024_11_17.png'],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約可'
      },
      {
        name: '当日予約'
      }
    ],
    // finished: dayjs().isAfter('2024-10-10'),
    details: {
      value: `メインイベントである人形供養祭のほか、豪華景品がゲットできる餅まきや抽選会を実施いたします。
また、模擬店も開催しているのでお気軽にご参加ください。`,
      schedule: [
        { name: '9:00~10:00', description: '人形受付' },
        {
          name: '9:00~無くなり次第終了',
          description: `ピー太郎とじゃんけん！ピーマンを貰おう！
友引市
模擬店
生花販売`
        },
        { name: '9:30~11:00', description: '源囃子連 演奏' },
        { name: '11:00~12:00', description: '供養祭式典' },
        { name: '12:00~13:00', description: '餅まき・抽選会' }
      ]
    }
  },
  {
    pageId: 4,
    title: 'やすらぎホール音楽祭 津軽民謡・津軽三味線他',
    metaTitle: `【参加無料】やすらぎホール音楽祭
津軽民謡歌手かすみさんのライブや津軽三味線など`,
    eventDate: '2024年11月23日(土)9:00~12:30',
    location: {
      name: 'JAやすらぎホール黒石',
      companyUrl: 'https://harada-kyodo.jp/hall-detail/814',
      googleMap: 'https://maps.app.goo.gl/XsiuhsCCCatTZDjx5'
    },
    contact: {
      name: 'JAやすらぎホール黒石',
      tel: '0120-7676-60'
    },
    reception: `事前予約は不要です。`,
    fee: `無料`,
    imgUrl: [
      '/images/events/2024_11_23-1.png',
      '/images/events/2024_11_23-2.png'
    ],
    tags: [
      {
        name: 'イベント'
      },
      {
        name: '事前予約不要'
      }
    ],
    details: {
      value: `ダンスパフォーマンスや津軽民謡歌手のかすみによる民謡ショー、さらには豪華ゲストのホリ・ハリウリサが登場する音楽祭を実施いたします。
また、地元野菜の直売店や写真撮影会、豪華景品が当たる抽選会も合わせて実施いたします。
さらに先着300名様限定で入場者に粗品をプレゼントもございますので、皆さん一緒に楽しみましょう！`,
      schedule: [
        { name: '9:20~', description: 'S.D.C.C. DANCE STUDIO' },
        {
          name: '9:50~',
          description: `正調黒石ねぷたばやし`
        },
        {
          name: '10:30~',
          description: `かすみの民謡ショー`
        },
        {
          name: '11:30~',
          description: `ホリ・ハリウリサ`
        }
      ]
    }
  }
].reverse() as Array<EventType>;

// {
//   title: '',
//   metaTitle:
//     '',
//   eventDate: '',
//   location: {
//     name: '',
//     companyUrl: '',
//     googleMap: ''
//   },
//   contact: {
//     name: '',
//     tel: ''
//   },
//   reception: ``,
//   fee: ``,
//   imgUrl: [''],
//   tags: [
//     {
//       name: ''
//     },
//   ],
//   details: {
//     value: ``,
//     schedule: [
//       { name: '', description: '' },
//       {
//         name: '',
//         description: ``
//       },
//     ]
//   }
// }
