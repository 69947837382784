import { useEffect, useState } from 'react';

const useDeviceType = (width: number) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= width);

  useEffect(() => {
    const checkDeviceType = () => {
      const isMobileDevice = window.innerWidth <= width;
      setIsMobile(isMobileDevice);
    };

    checkDeviceType();

    window.addEventListener('resize', checkDeviceType);

    return () => {
      window.removeEventListener('resize', checkDeviceType);
    };
  }, [width]);

  return isMobile;
};

export default useDeviceType;
