import React from 'react';
import Introduction from '../components/common/Introduction';
import PhoneConsultation from '../components/Urgent/PhoneConsultation';
import Information from '../components/Urgent/Information';
import AdditionalInformation from '../components/Urgent/AdditionalInformation';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { pages } from '../config/pages';

const UrgentContainer: React.FC<{}> = () => {
  return (
    <>
      <Introduction title={'お急ぎの方へ'}>
        <p>
          深夜・早朝であってもお問い合わせ受付しております。ご心配事、些細なご質問などご相談ください。{' '}
        </p>
        <p>事前相談がなかった場合でも、ご要望に合わせて対応いたします。</p>
      </Introduction>
      <Breadcrumbs subPages={[pages.urgent]} />
      <PhoneConsultation />
      <Information />
      <AdditionalInformation />
    </>
  );
};

export default UrgentContainer;
