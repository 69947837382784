import React from 'react';
import { useContactsModal } from '../../contexts/ContactsModalContext';
import Banner from '../common/Banner';

const FlowFinalElement: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <div className='rounded-lg bg-primary text-white [&>*]:text-white text-[16px]/[30px] pc:text-center pt-0 pc:pt-[30px] pb-[30px] px-[15px] pc:px-[150px] relative'>
      <div className='relative pc:absolute pc:left-[30px] -top-[6px] flex justify-center'>
        <Banner
          color='white'
          topText={'Flow'}
          bottomText={<span className='relative top-[0px]'>Fin</span>}
        />
      </div>
      {children}
      <p
        className='text-[16px]/[20px] mt-[15px] text-white relative cursor-pointer'
        onClick={handleClickContactsModal}
      >
        電話でのご相談・
        <br className='pc:hidden' />
        お問い合わせはこちら{' '}
        <div className='inline-block relative -top-[2px]'>
          <div className='h-[22px] w-[22px] rounded-full bg-white flex justify-center items-center'>
            <img
              src='/images/arrow_green.png'
              alt='右に指す矢印'
              className='h-[8px]'
            />
          </div>
        </div>
      </p>
    </div>
  );
};

export default FlowFinalElement;
