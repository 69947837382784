import React from 'react';

const InfoCardContainer: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <div
      className={`${
        className ?? ''
      } flex flex-col pc:grid pc:grid-cols-3 items-center pc:items-start gap-[20px]`}
    >
      {children}
    </div>
  );
};

export default InfoCardContainer;
