import React from 'react';

const TextBlock: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <div
      className={`${
        className ?? ''
      } text-[#333333] text-[18px] tracking-[0px] mid:text-[20px] text-center font-bold font-serif leading-loose mt-[10px] max-w-[700px] pc:max-w-none mx-auto`}
    >
      {children}
    </div>
  );
};

export default TextBlock;
