import React from 'react';

export type EventScheduleItemProps = {
  name: string;
  description: string;
};

const EventScheduleItem: React.FC<EventScheduleItemProps> = ({
  name,
  description
}) => (
  <tr>
    <td className='px-4 py-2 border border-white bg-[#DEF0EF]'>{name}</td>
    <td className='px-4 py-2 whitespace-pre-wrap'>{description}</td>
  </tr>
);

const EventScheduleTable: React.FC<{
  schedule?: Array<EventScheduleItemProps>;
}> = ({ schedule }) => {
  if (!schedule?.length) return null;
  return (
    <div className='flex flex-col mt-5'>
      <div className='text-[#333333] whitespace-pre-wrap w-full flex text-left items-center justify-start text-[16px]'>
        ● イベントスケジュール
      </div>
      <table className='w-full text-left mt-2'>
        {schedule?.map(({ name, description }) => (
          <EventScheduleItem name={name} description={description} />
        ))}
      </table>
    </div>
  );
};

export default EventScheduleTable;
