import React, { Fragment } from 'react';
import { PageInfo, pages } from '../../config/pages';
import { Link } from 'react-router-dom';

const Breadcrumbs: React.FC<{
  subPages: Array<PageInfo>;
}> = ({ subPages }) => (
  <div className='py-[12px] pl-[30px] hidden pc:block'>
    <p className='font-sans text-[11px]'>
      <Link to={pages.home.path} className='underline'>
        TOP
      </Link>
      {subPages.map((page, index) => (
        <Fragment key={page.path}>
          <span>{' > '}</span>
          {index !== subPages.length - 1 ?
            <Link to={page.path} className='underline'>
              {page.name}
            </Link>
          : <span>{page.name}</span>}
        </Fragment>
      ))}
    </p>
  </div>
);

export default Breadcrumbs;
