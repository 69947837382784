import React from 'react';

const AdditionalInformation: React.FC<{}> = () => {
  return (
    <section className='bg-[#F9F7F5] px-[10px] mid:px-[20px] py-[50px] pc:py-[70px] '>
      <div className='max-w-[700px] pc:max-w-[1066px] grid pc:grid-cols-2 gap-[40px] mx-auto'>
        <Window heading={'病院・施設で亡くなられた場合'}>
          <p>病院・施設でお亡くなりになられた場合、お帰り先を決めます。</p>
          <p className='mb-[20px]'>
            お電話をいただいた際に、故人様のお名前やお帰り先と合わせて、
            お葬式を上げる宗旨・宗派（仏式・神式など）をお伺いし、
            ご希望のお迎え希望時間に指定の病院・施設でお迎えに参ります。
          </p>
          <div className='rounded-lg p-[30px] bg-contrastBg'>
            <div className='border-b-[1px] border-b-[#AAAAAA]'>
              <h4 className='text-[18px]/[30px] text-center tracking-[0.32px] font-bold mb-[15px]'>
                退院・退所時に行うこと
              </h4>
            </div>
            <ul className='list-none mt-[15px]'>
              <li>◆ 入院費用の精算確認</li>
              <li>◆ 病院・施設内の荷物の対応ついて確認 </li>
              <li>◆ 死亡診断書の受取りと記載内容の確認</li>
            </ul>
          </div>
        </Window>
        <Window heading={'ご自宅で亡くなられた場合'}>
          <p className='mb-[20px]'>
            ご自宅でお亡くなりになられた場合は、かかりつけの病院へ連絡をして医師に相談していただきます。
            突然のご不幸であれば、一度警察へ連絡します。
          </p>
          <p className='mb-[20px]'>
            その後、お電話をいただいた際に、故人様のお名前やお葬式を上げる宗旨・宗派（仏式・神式など）、亡くなられた方の状態をお伺いし（ベットや布団で寝ていらっしゃったか？お洋服を着ていらっしゃるか？など）担当スタッフがご自宅にお伺いします。{' '}
          </p>
          <p>
            後は、すべて経験豊富なスタッフにお任せください。
            喪主様とお打合せをさせていただき、お葬式の詳細が決まりましたら、お通夜・告別式の準備や役所・火葬の代行手続きを行います。
          </p>
        </Window>
        <Window heading={'故人様の搬送・お預かり'} className='pc:col-span-2'>
          <p>
            ご遺体の搬送のみをご希望の場合でも、フリーダイヤルへお電話ください。
          </p>
          <p>
            昼夜・遠近を問わず、ご指定の場所へ当社の寝台車で速やかにお迎えに参ります。
          </p>
          <p>近距離から長距離まで、安全第一にご遺体を搬送いたします。</p>
        </Window>
      </div>
    </section>
  );
};

const Window: React.FC<{
  className?: string;
  heading: string;
  children: React.ReactNode;
}> = ({ className, heading, children }) => (
  <div
    className={`${
      className ?? ''
    } rounded-lg border-gold border overflow-hidden h-full bg-white`}
  >
    <div className='py-[20px] bg-gold text-center'>
      <h3 className='text-[20px]/[28px] text-white font-bold '>{heading}</h3>
    </div>
    <div className='p-[20px] text-[16px]/[30px] tracking-[0.32px]'>
      {children}
    </div>
  </div>
);

export default AdditionalInformation;
