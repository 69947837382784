import React from 'react';
import { Link } from 'react-router-dom';

const SmallLinkButton: React.FC<{
  className?: string;
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
}> = ({ className, href, children, onClick }) => {
  if (href) {
    return (
      <Link to={href}>
        <Wrapper className={className}>{children}</Wrapper>
      </Link>
    );
  }
  return (
    <Wrapper className={'cursor-pointer'} onClick={onClick}>
      {children}
    </Wrapper>
  );
};

const Wrapper: React.FC<{
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}> = ({ children, className, onClick }) => (
  <div
    onClick={onClick}
    className={`${
      className ?? ''
    } h-[70px] max-w-[300px] bg-primary rounded-full drop-shadow-[0px_6px_0px_#00000029] relative mx-auto px-[37px]`}
  >
    <div className='h-full flex justify-center items-center text-white [&>*]:text-white text-[20px] pc:text-[22px]'>
      {children}
    </div>
    <div className='flex items-center absolute right-[21px] top-0 h-full'>
      <img
        src='/images/arrow_white.png'
        alt='右に指す矢印'
        className='h-[13px] object-contain'
      />
    </div>
  </div>
);

export default SmallLinkButton;
