import React from 'react';
import Introduction from '../components/common/Introduction';
import { EventType } from '../components/Events/EventBox';
import Breadcrumbs from '../components/common/Breadcrumbs';
import EventDetail from '../components/Events/EventDetail';
import { useNavigate } from 'react-router-dom';
import LargeLinkButton from '../components/Funerals/LargeLinkButton';
import { pages } from '../config/pages';

const EventDetailContainer: React.FC<{
  event: EventType;
  eventId: number;
}> = ({ event, eventId }) => {
  const navigator = useNavigate();
  return (
    <>
      <Introduction title={event.title}>
        <p className=' whitespace-pre-line flex  items-center justify-center my-4'>
          {`H.R.D Funeralでは、様々なセミナー・イベントを随時開催しております。
  どなたでもご参加いただけますが、事前予約が必要なセミナー・イベントもございますので、
  ご参加をご希望の方はお早めにお申し込みください。
  `}
        </p>
      </Introduction>

      <Breadcrumbs
        subPages={[
          {
            path: `/events`,
            name: `イベント一覧 `
          },
          {
            path: `/events/${eventId}`,
            name: `${event.title}`
          }
        ]}
      />
      <div className='my-5 flex gap-8 max-w-5xl justify-start items-center mx-auto '>
        <div className='flex  items-center px-4'>
          <img src='/images/arrow.png' alt='arrow' className='h-[22px] pr-3' />
          <div
            className='text-[#007477] hover:underline cursor-pointer text-[20px] '
            onClick={() => {
              navigator('/events');
            }}
          >
            イベント一覧へ戻る
          </div>
        </div>
      </div>

      <div className='bg-[#F9F7F5] p-4'>
        <div className='my-5 flex flex-col gap-8 max-w-5xl justify-center items-center mx-auto '>
          <EventDetail event={event} />
        </div>
      </div>
      <div className='w-full flex justify-center my-4'>
        <LargeLinkButton
          className='mt-[20px] mx-auto'
          href={`${pages.events.path}`}
        >
          {'イベント一覧へ戻る'}
        </LargeLinkButton>
      </div>
    </>
  );
};

export default EventDetailContainer;
