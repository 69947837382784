import React from 'react';
import SubHeading from '../common/SubHeading';
import TextBlock from '../common/TextBlock';

const handleClickScrollButton = () => window.scrollTo(0, 0);

const Points: React.FC<{}> = () => {
  return (
    <div
      id='points'
      style={{ scrollMarginTop: '100px' }}
      className='mt-[50px] px-[20px] py-[50px] pc:p-[70px] bg-contrastBg'
    >
      <div className='max-w-[700px] pc:max-w-[1066px] mx-auto'>
        <SubHeading>葬儀場を選ぶポイント</SubHeading>
        <TextBlock className='mb-[40px]'>
          <p>
            後悔の無い葬儀が実施できるよう、
            式場選びのポイントとなる内容をまとめました。
          </p>
          <p>ご要望があった場合は、ご遠慮なくお問い合わせください。</p>
        </TextBlock>
        <div className='grid mid:grid-cols-2 pc:grid-cols-4 gap-[20px] justify-center items-center mb-[30px]'>
          <Point index={1} title='式場の広さ席数は適切か'>
            葬儀自体の規模と、参列者の数を踏まえてバランスを確認
          </Point>
          <Point index={2} title='設備環境がそろっているか'>
            ご要望に合わせ、柔軟に対応できる設備環境がそろっているか確認
          </Point>
          <Point index={3} title='交通アクセスが良好か'>
            参列者の都合を踏まえ、様々な来場パターンで集まりやすさを考慮
          </Point>
          <Point index={4} title='ご利用にあたって制限がないか'>
            式場によって提供内容が制限されている可能性があるため確認
          </Point>
        </div>
        <div className='text-right'>
          <p
            className='text-right cursor-pointer'
            onClick={handleClickScrollButton}
          >
            式場検索TOPへ{' '}
            <img
              className='inline-block -rotate-90 h-[25px] w-[25px] object-contain'
              src='/images/arrow.png'
              alt='上に指す矢印'
            />
          </p>
        </div>
      </div>
    </div>
  );
};

const Point: React.FC<{
  index: number;
  title: string;
  children: React.ReactNode;
}> = ({ index, title, children }) => {
  return (
    <div className='w-full max-w-[350px] relative text-center pc:h-full'>
      <div className='h-[27px]'></div>
      <div className='absolute rounded-full bg-primary w-[54px] h-[54px] top-0 left-[calc(50%-27px)] pt-[5px]'>
        <div className='mx-auto text-[10px] text-white w-fit font-stix'>
          Point
        </div>
        <div className='w-[10px] h-[2px] opacity-50 bg-white mx-auto'></div>
        <div className='mx-auto text-[20px] w-fit text-white italic font-stix'>
          {index.toString().padStart(2, '0')}
        </div>
      </div>
      <div className='rounded-lg overflow-hidden'>
        <div className='bg-white text-primary px-[45px] text-[20px]/[27px] pt-[40px] pb-[15px]'>
          {title}
        </div>
        <div className='bg-primary text-white py-[10px] px-[24px] text-[14px]/[20px] rounded-b-lg'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Points;
