import React from 'react';
import SubHeading from '../common/SubHeading';
import TextBlock from '../common/TextBlock';
import Services from '../common/Services';

const Arguments: React.FC<{ hasTagLine?: boolean }> = ({ hasTagLine }) => {
  return (
    <div className='p-[20px]'>
      <div className='max-w-[1066px] mx-auto  mt-[10px] mb-[50px] pc:mb-[70px]'>
        <SubHeading tagLine={hasTagLine ? 'SERVICE' : undefined}>
          H.R.D Funeralの
          <br className='mid:hidden' />
          3つの安心
        </SubHeading>
        <TextBlock>
          <p>
            H.R.D
            Funeralでは、故人様・喪主様のご要望に合わせて葬儀を提供するため
          </p>
          <p>
            おもてなしの品質にこだわり、ご安心いただける充実のサービスをご用意しています。
          </p>
        </TextBlock>
        <Services />
      </div>
    </div>
  );
};

export default Arguments;
