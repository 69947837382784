import React from 'react';
import SpeechBubble from '../common/SpeechBubble';
import { useContactsModal } from '../../contexts/ContactsModalContext';

const PhoneConsultation: React.FC = () => {
  return (
    <div className='bg-[#F8C2B7] px-[10px] mid:px-[20px] pc:px-[50px] py-[40px]'>
      <div className='bg-white rounded-xl mid:rounded-3xl grid grid-cols-1 pc:grid-cols-[40%_60%] overflow-hidden max-w-[700px] pc:max-w-[1000px] mx-auto'>
        <Picture />
        <Information />
        <SpeechBubbles />
      </div>
    </div>
  );
};

const Picture = () => (
  <div className='rounded-md overflow-hidden relative m-[30px] hidden pc:flex items-center'>
    <img
      className='relative'
      src='/images/urgent/call_center.png'
      alt='コールセンタ員の写真'
    ></img>
    <div className='absolute rounded-full bg-[#F7E762] left-[20px] top-[20px] w-[100px] h-[100px] flex justify-center items-center'>
      <div>
        <div className='border-y border-[#C85554] h-[3px] w-[48px]'></div>
        <div className='text-[#C85554] font-sans font-bold'>受付中</div>
        <div className='border-y border-[#C85554] h-[3px] w-[48px]'></div>
      </div>
    </div>
  </div>
);

const Information = () => (
  <div className='font-sans mx-[10px] my-[20px] pc:m-[30px]'>
    <div className='mx-auto mid:max-w-[550px] mid:flex gap-[10px] flex-wrap grid grid-cols-2'>
      <GoldPanel className='sp:col-span-2 text-[16px]'>
        24時間365日受付
      </GoldPanel>
      <GoldPanel className='text-[13px] mid:text-[16px]'>
        通話料・事前相談無料
      </GoldPanel>
      <GoldPanel className='text-[13px] mid:text-[16px]'>
        携帯電話からもOK
      </GoldPanel>
    </div>
    <div className='m-[10px]'>
      <p className='text-[#C85554] text-[22px] mid:text-[30px] pc:text-[34px] text-center font-bold'>
        ご危篤・ご逝去でお急ぎの方へ
      </p>
    </div>
    <div className='text-center text-[16px]/[30px]'>
      <p>夜間・早朝でもご対応いたします。 </p>
      <p>些細な相談でも受付可能ですので、ご遠慮なくお問い合わせください。</p>
    </div>
    <div className='mt-[10px]'>
      <CallToActionButton />
    </div>
  </div>
);

const SpeechBubbles = () => (
  <div className='col-span-2 w-full bg-[#FFF1EA] py-[20px] px-[40px]'>
    <img
      src='/images/illustration/question_heading_red.png'
      alt='こんなご要望にすぐにお応えします'
      className='h-[35px] hidden mid:block mx-auto mb-[20px]'
    />
    <img
      src='/images/illustration/question_heading_red_sp.png'
      alt='いろいろな依頼を行っている吹き出し'
      className='h-[45px] mid:hidden block mx-auto mb-[20px]'
    />
    <div className='flex flex-wrap gap-[20px] justify-center'>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        葬儀を依頼したい
      </SpeechBubble>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        式場を予約したい
      </SpeechBubble>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        故人を自宅や斎場へ搬送してほしい
      </SpeechBubble>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        葬儀費用について相談したい
      </SpeechBubble>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        何を準備すべきか聞きたい
      </SpeechBubble>
      <SpeechBubble color='red' arrowClass='left-[20px]'>
        緊急の相談に乗ってほしい
      </SpeechBubble>
    </div>
  </div>
);

const GoldPanel: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => (
  <div
    className={`text-[#ffffff] rounded-md bg-gold flex justify-center items-center grow px-[15px] py-[5px] ${
      className ?? ''
    } `}
  >
    {children}
  </div>
);

const CallToActionButton = () => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <div
      onClick={handleClickContactsModal}
      className='cursor-pointer mx-auto w-full max-w-[400px] pc:max-w-[550px] bg-[#C85554] rounded-full py-[5px] flex justify-center items-center shadow-normal relative'
    >
      <div className='grid grid-cols-[1fr_20px] gap-[10px] p-[20px] w-full'>
        <div className='flex gap-[10px] justify-center'>
          <div className='pc:w-[45px] w-[30px]'>
            <img
              src='/images/phone.png'
              alt='電話のアイコン'
              className='h-full w-full object-contain'
            />
          </div>
          <div className='text-white font-bold pc:text-[30px] text-[22px] flex items-center tracking-[0.7px]'>
            お電話での相談
          </div>
        </div>
        <div className='flex items-center'>
          <img
            src='/images/arrow_white.png'
            alt='右へ指す矢印'
            className='w-[11px] absolute right-[20px]'
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneConsultation;
