import React from 'react';
import Introduction from '../components/common/Introduction';
import { pages } from '../config/pages';
import EventBox from '../components/Events/EventBox';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { eventList } from '../data/eventList';

const EventsContainer: React.FC<{}> = () => {
  return (
    <>
      <Introduction title={pages.events.name}>
        <p className=' whitespace-pre-line flex  items-center justify-center my-4'>
          {`H.R.D Funeralでは、様々なセミナー・イベントを随時開催しております。
  どなたでもご参加いただけますが、事前予約が必要なセミナー・イベントもございますので、
  ご参加をご希望の方はお早めにお申し込みください。
  `}
        </p>
      </Introduction>
      <Breadcrumbs subPages={[pages.events]} />
      <div className='bg-[#F9F7F5] p-4'>
        <div className='m-5 flex flex-col gap-8 max-w-5xl justify-center items-center mx-auto bg-[#F9F7F5]'>
          {eventList.map((event, index) => (
            <EventBox key={event.title} event={event} eventId={event.pageId} />
          ))}
        </div>
      </div>
    </>
  );
};

export default EventsContainer;
