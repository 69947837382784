import { StrictMode } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import React from 'react';
import { pages } from './config/pages';
import HomeContainer from './pages/HomeContainer';
import UrgentContainer from './pages/UrgentContainer';
import StrengthsContainer from './pages/StrengthsContainer';
import FuneralsContainer from './pages/FuneralsContainer';
import GeneralFuneralTab from './components/Funerals/General/GeneralFuneralTab';
import CremationFuneralTab from './components/Funerals/Cremation/CremationFuneralTab';
import PrivateFuneralTab from './components/Funerals/Private/PrivateFuneralTab';
import HallsContainer from './pages/HallsContainer';
import EventsContainer from './pages/EventsContainer';
import EventDetailContainer from './pages/EventDetailContainer';
import { EventType } from './components/Events/EventBox';
import { eventList } from './data/eventList';

// Start of Selection

const eventRoutes = eventList.map((event: EventType, index: number) => ({
  path: `/events/${event.pageId}`,
  element: <EventDetailContainer event={event} eventId={event.pageId} />
}));

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <HomeContainer />
      },
      {
        path: '*',
        element: <Navigate to='/' replace />
      },
      {
        path: pages.urgent.path,
        element: <UrgentContainer />
      },
      {
        path: pages.strengths.path,
        element: <StrengthsContainer />
      },
      {
        path: pages.funerals.path,
        element: <FuneralsContainer />,
        children: [
          { path: '', element: <GeneralFuneralTab /> },
          {
            path: pages.generalFuneral.path,
            element: <GeneralFuneralTab />
          },
          {
            path: pages.cremationFuneral.path,
            element: <CremationFuneralTab />
          },
          {
            path: pages.privateFuneral.path,
            element: <PrivateFuneralTab />
          }
        ]
      },
      { path: pages.halls.path, element: <HallsContainer /> },
      { path: pages.events.path, element: <EventsContainer /> },
      ...eventRoutes
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
