import { companySiteUrl, pages, privacyPolicyUrl } from '../../config/pages';
import { useContactsModal } from '../../contexts/ContactsModalContext';
import { Link, createSearchParams } from 'react-router-dom';
import { prefectures } from '../../config/constants';
import React from 'react';

const SupportInformation = () => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <>
      <div className='sp:hidden'>
        <div className='h-[429px] w-full bg-primary flex flex-col justify-between items-center py-[70px]'>
          <p className='text-[37px] font-bold text-white'>無料電話相談窓口</p>
          <p className='text-[20px] text-white text-center'>
            費用やプラン内容・事前相談など、葬儀のことに関してなんでもお問い合わせください。
            <br />
            専任の担当者が丁寧に対応いたします。
          </p>
          <div
            className='h-[110px] w-[775px] bg-white rounded-[62px] flex justify-center items-center shadow-normal relative cursor-pointer'
            onClick={handleClickContactsModal}
          >
            <img
              src='/images/phone_green.png'
              alt='phone_green'
              className='w-[53px] relative mr-[20px]'
            />
            <div className='flex flex-col items-center justify-center mr-[40px]'>
              <p className='text-primary font-bold text-[30px] mb-2'>
                お電話での相談
              </p>
              <div className='flex flex-row justify-center'>
                <img
                  src='/images/ribbon_long.png'
                  alt='ribbon_long'
                  className='h-[33px]'
                />
                <p className='absolute text-white text-[18px] font-bold'>
                  通話無料/携帯電話可能・24時間365日受付中
                </p>
              </div>
            </div>
            <img
              src='/images/arrow_green.png'
              alt='arrow_green'
              className='w-[11px] absolute right-[35px] top-[calc(50%-10px)]'
            />
          </div>
        </div>
        <div className='h-[376px] bg-[#F9F7F5] w-full px-[50px] py-[50px] flex flex-col items-center justify-between'>
          <p className='text-[35px] font-serif'>
            H.R.D Funeral | 心のかようお葬式
          </p>
          <div className='flex flex-row justify-evenly w-full gap-[70px]'>
            <div className='flex flex-col'>
              <Link to={pages.home.path}>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>TOP</p>
              </Link>
              <Link to={pages.urgent.path}>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>
                  お急ぎの方へ
                </p>
              </Link>
              <Link to={pages.strengths.path}>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>
                  H.R.D Funeralの特長
                </p>
              </Link>
              <Link to={pages.funerals.path}>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>
                  H.R.D Funeralの葬儀
                </p>
              </Link>
              <Link to={pages.events.path}>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>
                  イベント一覧
                </p>
              </Link>
            </div>
            <div className='border-[1px] border-[#A5A5A5] border-dashed h-[175px]' />

            <div className='flex flex-col grow max-w-[600px]'>
              <p className='text-[16px] font-bold text-[#777777] mb-[22px]'>
                式場一覧
              </p>
              <div className='grid grid-cols-4 gap-[12px]'>
                {prefectures.map((prefecture, index) => (
                  <PrefectureLink linkName={prefecture} key={index}>
                    {prefecture}
                  </PrefectureLink>
                ))}
              </div>
            </div>
            <div className='border-[1px] border-[#A5A5A5] border-dashed h-[175px]' />
            <div className='flex flex-col'>
              <Link to={companySiteUrl} target='_blank' rel='noreferrer'>
                <p className='text-[16px] font-bold text-[#777777] mb-3'>
                  運営会社
                </p>
              </Link>
              <Link to={privacyPolicyUrl} target='_blank' rel='noreferrer'>
                <p className='text-[16px] font-bold text-[#777777]'>
                  個人情報保護方針
                </p>
              </Link>
            </div>
          </div>
        </div>
        <div className='h-[50px] bg-primary w-full flex items-center justify-center'>
          <p className='text-white text-[16px]'>Copyright © H.R.D Funeral</p>
        </div>
      </div>
      <div className='pc:hidden'>
        <div className='bg-primary py-[50px] px-[20px] flex flex-col items-center'>
          <p className='text-[30px] font-bold mb-[10px] text-white'>
            無料電話相談窓口
          </p>
          <p className='text-[16px] mb-[30px] text-center text-white'>
            費用やプラン内容・事前相談など、葬儀のことに関してなんでもお問い合わせください。
            <br />
            専任の担当者が丁寧に対応いたします。
          </p>
          <div className='h-[130px] w-full max-w-[500px] flex flex-col bg-white rounded-full justify-center items-center relative p-[20px]'>
            <div
              className='flex flex-row items-center cursor-pointer'
              onClick={handleClickContactsModal}
            >
              <img
                src='/images/phone_green.png'
                alt='phone_green'
                className='w-[29px] mr-2'
              />
              <p className='text-[28px] font-bold text-primary'>
                お電話での相談
              </p>
            </div>
            <div className='flex flex-row justify-center items-center'>
              <img
                src='/images/ribbon_sp.png'
                alt='ribbon_sp'
                className='h-[51px]'
              />
              <p className='absolute font-bold text-[#FFFFFF] text-[16px] text-center'>
                通話無料/携帯電話可能・
                <br />
                24時間365日受付中
              </p>
              <img
                src='/images/arrow_green.png'
                alt='arrow_green'
                className='w-[11px] absolute right-[35px] top-[calc(50%-10px)]'
              />
            </div>
          </div>
        </div>
        <div className='bg-[#F9F7F5] h-[401px] flex flex-col items-center justify-evenly'>
          <p className='text-[22px] font-serif'>
            H.R.D Funeral | 心のかようお葬式
          </p>
          <div className='flex flex-col items-center justify-between h-[250px]'>
            <Link to={pages.home.path}>
              <p className='text-[16px] text-[#777777]'>TOP</p>
            </Link>
            <Link to={pages.strengths.path}>
              <p className='text-[16px] text-[#777777]'>H.R.D Funeralの特長</p>
            </Link>
            <Link to={pages.funerals.path}>
              <p className='text-[16px] text-[#777777]'>H.R.D Funeralの葬儀</p>
            </Link>
            <Link to={pages.events.path}>
              <p className='text-[16px] text-[#777777]'>イベント一覧</p>
            </Link>
            <Link to={pages.halls.path}>
              <p className='text-[16px] text-[#777777]'>式場一覧</p>
            </Link>
            <Link to={pages.urgent.path}>
              <p className='text-[16px] text-[#777777]'>お急ぎの方へ</p>
            </Link>
            <Link to={companySiteUrl} target='_blank' rel='noreferrer'>
              <p className='text-[16px] text-[#777777]'>運営会社</p>
            </Link>
            <Link to={privacyPolicyUrl} target='_blank' rel='noreferrer'>
              <p className='text-[16px] text-[#777777]'>個人情報保護方針</p>
            </Link>
          </div>
        </div>
        <div className='h-[34px] bg-primary w-full flex items-center justify-center'>
          <p className='text-white text-[12px]'>Copyright © H.R.D Funeral</p>
        </div>
      </div>
    </>
  );
};

const PrefectureLink: React.FC<{
  children: React.ReactNode;
  linkName: string;
}> = ({ children, linkName }) => (
  <Link
    to={`${pages.halls.path}?${createSearchParams({
      prefecture: linkName
    })}`}
  >
    <p className='text-[14px] text-[#777777]'>{children}</p>
  </Link>
);

export default SupportInformation;
