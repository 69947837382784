import React from 'react';
import Tab from '../Tab';
import SubHeading from '../../common/SubHeading';
import TextBlock from '../../common/TextBlock';
import CremationFlow from './CremationFlow';
import FuneralExample from '../FuneralExample';
import GoldWindow from '../GoldWindow';
import LargeLinkButton from '../LargeLinkButton';
import { pages } from '../../../config/pages';

const CremationFuneralTab: React.FC<{}> = () => {
  return (
    <Tab funeralType='cremation'>
      <div className='bg-contrastBg w-full'>
        <div className='py-[50px] pc:py-[70px] max-w-[1066px] mx-auto px-[20px]'>
          <SubHeading>火葬式の流れとポイント</SubHeading>
          <TextBlock className='mb-[30px]'>
            <p>
              火葬式の一般的な流れとともに、準備や対応にあたってのポイントもご紹介をいたします。
            </p>
          </TextBlock>
          <CremationFlow />
          <SubHeading>火葬式のご利用事例</SubHeading>
          <TextBlock>
            <p>H.R.D Funeralが提供する火葬式のご利用事例をご紹介いたします。</p>
          </TextBlock>
          <FuneralExample
            title={'限られた人でシンプルに送りたい。'}
            listTitle={'お客様の状況・ご要望'}
            listElements={[
              '無宗教なのでお坊さんにお願いする必要がない。',
              '遺族だけでお見送りできればいい。',
              'できるだけ費用や精神的負担を少なくしたい。'
            ]}
            services={['寝台車', '御棺', 'ご遺体保全', '骨壺', '霊柩車']}
            amountOfPeopleText={
              <span>
                <span className='text-primary'>5名</span>
                <span className='text-black font-normal'>程度</span>
              </span>
            }
            funeralTypeText={'火葬式'}
            funeralStyleText={'すべての宗旨'}
          />
          <GoldWindow title='お客様から頂いた声' className='mt-[20px]'>
            <p>
              本人の希望で質素にやりました。お坊さんもたのまず、戒名もつけず、
              自分が名前を書いておきました。火葬をしている時間内に皆さんに清めをやっていただきました。
              職員さんが親切に良くして下さいました。本当にありがとうございました
            </p>
          </GoldWindow>
          <div className='w-full'>
            <LargeLinkButton
              className='mt-[25px] pc:mt-[50px] mx-auto'
              href={`${pages.halls.path}?ceremonyType=火葬式`}
            >
              火葬式が実施可能な式場を探す
            </LargeLinkButton>
          </div>
        </div>
      </div>
    </Tab>
  );
};

export default CremationFuneralTab;
