import React, { Children } from 'react';
import SubHeading from '../common/SubHeading';
import TextBlock from '../common/TextBlock';
import reviewsDataJson from '../../data/reviews.json';

const Reviews: React.FC<{}> = () => {
  const reviewsData = reviewsDataJson.data;
  const [sliderHeight, setSliderHeight] = React.useState(0);
  return (
    <div className='overflow-x-hidden w-full px-[20px]'>
      <div className='my-[50px] pc:my-[70px] mx-auto mid:max-w-[700px] pc:max-w-[1066px] relative'>
        <SubHeading>お客様の声</SubHeading>
        <TextBlock>
          <p>H.R.D Funeralをご利用いただいた、お客様の声をご紹介します。</p>
        </TextBlock>
        <Slider
          style={{ height: `${sliderHeight > 397 ? sliderHeight : 397}px` }}
          className={sliderHeight > 0 ? '[&>*]:h-full [&>*>*]:h-full' : ''}
        >
          {reviewsData.map((reviewData) => {
            return (
              <Review
                title={reviewData.userName + '様'}
                funeralType={reviewData.type}
                attendees={reviewData.number}
                setSliderHeight={setSliderHeight}
                key={reviewData.id}
              >
                <p>{reviewData.comment}</p>
              </Review>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

const Review: React.FC<{
  title: string;
  funeralType: string;
  attendees: number;
  children: React.ReactNode;
  setSliderHeight: (height: (height: number) => number) => void;
}> = ({ title, funeralType, attendees, children, setSliderHeight }) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    setSliderHeight((currentHeight: number) => {
      if (!ref.current) return currentHeight;
      const height = (ref.current as HTMLDivElement).clientHeight;
      if (height > currentHeight) {
        return height;
      }
      return currentHeight;
    });
  }, [setSliderHeight]);
  return (
    <div
      ref={ref}
      className='border-gold border-[1px] rounded-lg max-w-[512px] w-[calc(100vw-40px)]'
    >
      <div className='border-b-gold border-b-[1px] flex justify-between px-[30px] py-[12px]'>
        <div className='text-gold text-[18px]/[28px]'>{title}</div>
        <div className='flex gap-[10px]'>
          <div className='rounded-md bg-gold text-white flex justify-center items-center min-w-[85px] min-h-[30px] px-[20px] py-[4px]'>
            {funeralType}
          </div>
          <div className='rounded-md bg-contrastBg text-gold flex justify-center items-center min-w-[85px] min-h-[30px] px-[20px] py-[4px]'>
            {attendees}名
          </div>
        </div>
      </div>
      <div className='px-[30px] py-[20px] text-[16px]/[30px] tracking-[0.32px] '>
        {children}
      </div>
    </div>
  );
};

const Slider: React.FC<{
  children: React.ReactNode;
  style: React.CSSProperties;
  className: string;
}> = ({ children, style, className }) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.onresize = () => {
      setWindowWidth(window.innerWidth);
    };
  }, []);
  const childArray = Children.toArray(children);
  const [elements, setElements] = React.useState([
    ...childArray,
    ...childArray
  ]);
  const length = Children.count(children);
  const [index, setIndexInternal] = React.useState(0);
  const setIndex = (newIndex: number) => {
    if (newIndex % length === 0) {
      setElements([...elements, ...childArray]);
    }
    if (newIndex < 0) return;
    setIndexInternal(newIndex);
  };
  const incrementIndexUp = () => setIndex(index + 1);

  const incrementIndexDown = () => setIndex(index - 1);
  const handleClickCircle = (newIndex: number) => () => {
    if (index > length) {
      setIndex(newIndex + Math.floor(index / length) * length);
    } else setIndex(newIndex);
  };
  const slideWidth = windowWidth >= 512 ? 512 : windowWidth - 40;
  const slideGap = 40;
  const [touchStart, setTouchStart] = React.useState<number | null>(null);
  const [touchEnd, setTouchEnd] = React.useState<number | null>(null);

  //https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react
  const minSwipeDistance = 40;
  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };
  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) incrementIndexUp();
    else if (isRightSwipe) incrementIndexDown();
  };
  const translateX = (slideWidth + slideGap) * index;

  return (
    <div className='mt-[40px] min-h-[397px]'>
      <div
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        style={{
          ...style,
          transform: `translateX(-${translateX}px)`
        }}
        className={`${className} relative w-full duration-500`}
      >
        {elements.map((child, childIndex) => (
          <div
            className='absolute first:relative top-0'
            style={{
              left: `${(slideWidth + slideGap) * childIndex}px`
            }}
            key={childIndex}
          >
            {child}
          </div>
        ))}
      </div>
      <div className='flex gap-[8px] w-full pc:justify-center mt-[50px]'>
        {Children.toArray(children).map((_child, childIndex) => (
          <button
            key={childIndex}
            onClick={handleClickCircle(childIndex)}
            className={`rounded-full h-[9px] w-[9px] ${
              index % length === childIndex ? 'bg-primary' : 'bg-contrastBg'
            }`}
          />
        ))}
      </div>
      <div className='flex gap-[10px] absolute bottom-0 right-0 translate-y-[calc(50%-4px)]'>
        <button
          onClick={incrementIndexDown}
          className='rounded-full h-[50px] w-[50px] bg-primary flex justify-center items-center rotate-180'
        >
          <img
            src='/images/arrow_white.png'
            alt='左に指す矢印'
            className='h-[12px] object-contain'
          />
        </button>
        <button
          onClick={incrementIndexUp}
          className='rounded-full h-[50px] w-[50px] bg-primary flex justify-center items-center'
        >
          <img
            src='/images/arrow_white.png'
            alt='右に指す矢印'
            className='h-[12px] object-contain'
          />
        </button>
      </div>
    </div>
  );
};
export default Reviews;
