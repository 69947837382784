import React from 'react';

type TagBadgeProps = {
  tag: {
    name: string;
    disabled?: boolean;
  };
};

const TagBadge: React.FC<TagBadgeProps> = ({ tag }) => {
  return (
    <div
      className={` text-center ${
        !tag?.disabled ? 'bg-[#9F8A43] text-white' : 'bg-[#9B9B9B] text-white'
      } px-4 py-1 font-serif rounded-lg text-[13px] min-w-[80px]`}
      style={{ fontFamily: "'Noto Sans JP'" }}
    >
      {tag.name}
    </div>
  );
};

export default TagBadge;
