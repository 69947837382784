import React from 'react';

const Banner: React.FC<{
  color?: 'white' | 'green';
  className?: string;
  topText: string;
  bottomText: React.ReactNode;
}> = ({ color, className, topText, bottomText }) => {
  return (
    <div
      className={`${className ?? ''} font-[STIXGeneral] w-[85px] h-[100.5px] ${
        color === 'white' ?
          "bg-[url('/public/images/white_banner.svg')]"
        : "bg-[url('/public/images/green_banner.svg')]"
      } ${className && className.includes('absolute') ? '' : 'relative'}`}
    >
      <div
        className={`w-[74px] h-[85px] absolute flex justify-center items-center text-center ${
          color === 'white' ? 'text-primary' : 'text-white'
        }`}
      >
        <div className={`grid grid-rows-[15px_15px_40px] text-center mt-[5px]`}>
          <div className='font-bold text-[14px] flex justify-center items-center'>
            {topText}
          </div>
          <div className='flex justify-center items-center'>
            <div
              className={`h-[1px] w-[15px] ${
                color === 'white' ? 'bg-primary' : 'bg-white'
              }`}
            ></div>
          </div>
          <div className='italic text-[34px] font-black flex justify-center items-center'>
            {bottomText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
