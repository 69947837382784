import React from 'react';
import { useContactsModal } from '../../contexts/ContactsModalContext';

const Footer: React.FC<{}> = () => {
  const { handleClickContactsModal } = useContactsModal();

  return (
    <footer className='pc:pt-[100px] sp:pt-[90px] z-40 relative'>
      <div className='sp:hidden h-[100px] flex flex-row w-full bg-gradient-to-b from-[#007477] to-[#47AA9F] fixed left-0 bottom-0 items-center justify-evenly z-50'>
        <div className='h-[53px] w-[303px] border-y border-[#FFFFFF] flex items-center justify-center'>
          <p className='font-serif text-5 text-[#FFFFFF]'>
            葬儀のご相談 ご依頼・お見積
          </p>
        </div>
        <div
          onClick={handleClickContactsModal}
          className='shadow-normal h-[70px] w-[30%] rounded-[62px] bg-[#ffffff] flex flex-row justify-center items-center cursor-pointer'
        >
          <img
            src='/images/phone_green.png'
            alt='phone_green'
            className='h-[35px] mr-1'
          />
          <div>
            <p className='text-[22px] text-[#007477]'>お電話相談</p>
            <p className='text-[14px] text-[#007477]'>24時間365日受付</p>
          </div>
        </div>
        <div
          className='shadow-normal h-[70px] w-[30%] rounded-[62px] bg-[#ffffff] flex flex-row justify-center items-center cursor-pointer'
          onClick={() => (window as any).ChatplusScript.openChat()}
        >
          <img
            src='/images/chat_green.png'
            alt='chat_green'
            className='h-[35px] mr-2'
          />
          <p className='text-[22px] text-[#007477]'>チャットでご相談</p>
        </div>
      </div>
      <div className='pc:hidden h-[90px] flex flex-row w-full bg-gradient-to-b from-[#007477] to-[#47AA9F] fixed left-0 bottom-0 items-center justify-evenly'>
        <div className='h-[53px] w-[111px] border-y border-[#FFFFFF] flex items-center justify-center'>
          <div className='text-center'>
            <p className='font-serif text-[15px] text-[#FFFFFF]'>
              葬儀のご相談
            </p>
            <p className='font-serif text-[15px] text-[#FFFFFF]'>
              ご依頼・お見積
            </p>
          </div>
        </div>
        <div
          onClick={handleClickContactsModal}
          className='shadow-normal h-[70px] w-[157px] rounded-[62px] bg-[#ffffff] flex flex-row justify-center items-center cursor-pointer'
        >
          <img
            src='/images/phone_green.png'
            alt='phone_green'
            className='h-[22px] mr-1'
          />
          <div>
            <p className='text-[19px] text-[#007477]'>お電話相談</p>
            <p className='text-[12px] text-[#007477]'>24時間365日受付</p>
          </div>
        </div>
        <div
          className='shadow-normal h-[70px] w-[70px] rounded-[62px] bg-[#ffffff] flex justify-center items-center cursor-pointer'
          onClick={() => (window as any).ChatplusScript.openChat()}
        >
          <img
            src='/images/chat_green.png'
            alt='chat_green'
            className='h-[32px]'
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
