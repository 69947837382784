import { pages } from './pages';

export type Prefecture =
  | '北海道'
  | '青森県'
  | '岩手県'
  | '宮城県'
  | '秋田県'
  | '山形県'
  | '福島県'
  | '茨城県'
  | '栃木県'
  | '群馬県'
  | '埼玉県'
  | '千葉県'
  | '東京都'
  | '神奈川県'
  | '新潟県'
  | '富山県'
  | '石川県'
  | '福井県'
  | '山梨県'
  | '長野県'
  | '岐阜県'
  | '静岡県'
  | '愛知県'
  | '三重県'
  | '滋賀県'
  | '京都府'
  | '大阪府'
  | '兵庫県'
  | '奈良県'
  | '和歌山県'
  | '鳥取県'
  | '島根県'
  | '岡山県'
  | '広島県'
  | '山口県'
  | '徳島県'
  | '香川県'
  | '愛媛県'
  | '高知県'
  | '福岡県'
  | '佐賀県'
  | '長崎県'
  | '熊本県'
  | '大分県'
  | '宮崎県'
  | '鹿児島県'
  | '沖縄県';

export const prefectures: Array<Prefecture> = [
  '青森県',
  '山形県',
  '群馬県',
  '茨城県',
  '栃木県',
  '埼玉県',
  '東京都',
  '静岡県',
  '兵庫県',
  '岡山県',
  '広島県',
  '愛媛県'
];

export const prefecturesFooter = [
  '青森県',
  '山形県',
  '群馬県',
  '茨城県',
  '栃木県',
  '埼玉県',
  '東京都',
  '静岡県',
  '兵庫県(山崎エリア)',
  '兵庫県(丹波エリア)',
  '岡山県',
  '広島県(広島市)',
  '広島県(福山市)',
  '愛媛県'
];

export const funeralTypes = ['cremation', 'private', 'general'] as const;
export const funeralTypeLabelMap: Record<
  (typeof funeralTypes)[number],
  string
> = {
  cremation: '火葬式',
  general: '一般葬',
  private: '家族葬'
};
export const funeralTypePathMap: Record<(typeof funeralTypes)[number], string> =
  {
    cremation: pages.cremationFuneral.path,
    general: pages.generalFuneral.path,
    private: pages.privateFuneral.path
  };

//厚生労働省により公式な都道府県の順番　https://www.mhlw.go.jp/topics/2007/07/dl/tp0727-1d.pdf
export const prefectureOrder: Record<Prefecture, number> = {
  北海道: 1,
  青森県: 2,
  岩手県: 3,
  宮城県: 4,
  秋田県: 5,
  山形県: 6,
  福島県: 7,
  茨城県: 8,
  栃木県: 9,
  群馬県: 10,
  埼玉県: 11,
  千葉県: 12,
  東京都: 13,
  神奈川県: 14,
  新潟県: 15,
  富山県: 16,
  石川県: 17,
  福井県: 18,
  山梨県: 19,
  長野県: 20,
  岐阜県: 21,
  静岡県: 22,
  愛知県: 23,
  三重県: 24,
  滋賀県: 25,
  京都府: 26,
  大阪府: 27,
  兵庫県: 28,
  奈良県: 29,
  和歌山県: 30,
  鳥取県: 31,
  島根県: 32,
  岡山県: 33,
  広島県: 34,
  山口県: 35,
  徳島県: 36,
  香川県: 37,
  愛媛県: 38,
  高知県: 39,
  福岡県: 40,
  佐賀県: 41,
  長崎県: 42,
  熊本県: 43,
  大分県: 44,
  宮崎県: 45,
  鹿児島県: 46,
  沖縄県: 47
};
