import React, { useCallback, useEffect, useState } from 'react';
import { pages } from '../../config/pages';
import { useContactsModal } from '../../contexts/ContactsModalContext';
import { Link, useLocation } from 'react-router-dom';
import TopBanner from './TopBanner';
import useDeviceType from '../hooks/useDeviceType';
import { eventList } from '../../data/eventList';

const Header = () => {
  const { pathname } = useLocation();
  const isMobile = useDeviceType(672);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [bannerStatus, setBannerStatus] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickModal = useCallback(
    () => setIsOpenModal(!isOpenModal),
    [isOpenModal]
  );
  useEffect(() => {
    setIsOpenModal(false);
  }, [pathname]);
  const { handleClickContactsModal } = useContactsModal();

  return (
    <>
      {bannerStatus && (
        <TopBanner
          setBannerStatus={setBannerStatus}
          items={eventList
            .filter((event) => !event?.finished)
            .map((event) => {
              return {
                text: event.metaTitle,
                backgroundColor: 'bg-[#007477]',
                link: `events/${event.pageId}`
              };
            })}
        />
      )}

      <header className='pc:pb-[70px] sp:pb-[50px] z-50 relative'>
        <div
          className={`sp:hidden h-[70px] w-full flex flex-row justify-between shadow-normal fixed left-0 ${bannerStatus ? 'top-120' : 'top-0'} bg-[#ffffff]`}
          style={
            bannerStatus ?
              {
                transform: `translateY(${-Math.min(scrollPosition, isMobile ? 114 : 75)}px)`,
                transition: 'transform 0.0s ease'
              }
            : {}
          }
        >
          <Link to={pages.home.path} className='self-center'>
            <div className='flex flex-row ml-[29px] items-center '>
              <img
                src='/images/harada_icon.png'
                alt='harada icon'
                className='h-[30px] mr-3'
              />
              <p className='italic text-[#9F8A43] font-garamond text-[39px] font-bold'>
                H.R.D Funeral
              </p>
            </div>
          </Link>
          <div className='flex flex-row'>
            <Link to={pages.strengths.path}>
              <div className='h-[70px] border-l border-[#DDDDDD] flex justify-center items-center px-[20px]'>
                <p className='text-[16px]'>{pages.strengths.name}</p>
              </div>
            </Link>
            <Link to={pages.funerals.path}>
              <div className='h-[70px] border-l border-[#DDDDDD] flex justify-center items-center px-[20px]'>
                <p className='text-[16px]'>{pages.funerals.name}</p>
              </div>
            </Link>
            <Link to={pages.halls.path}>
              <div className='h-[70px] border-l border-[#DDDDDD] flex justify-center items-center px-[20px]'>
                <p className='text-[16px]'>式場一覧</p>
              </div>
            </Link>
            <Link to={pages.events.path}>
              <div className='h-[70px] border-l border-[#DDDDDD] flex justify-center items-center px-[20px]'>
                <p className='text-[16px]'>{pages.events.name}</p>
              </div>
            </Link>
            <Link to={pages.urgent.path}>
              <div className='h-[70px] flex justify-center items-center bg-[#C85554] px-[20px]'>
                <img
                  src='/images/circle_exclamation_solid.png'
                  alt='circle-exclamation-solid'
                  className='h-[27px] mr-1'
                />
                <p className='text-[#FFFFFF] text-[20px]'>
                  {pages.urgent.name}
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div
          className='pc:hidden h-[50px] flex flex-row justify-between shadow-normal pl-[15px] w-full fixed left-0   bg-[#ffffff]'
          style={
            bannerStatus ?
              {
                transform: `translateY(${-Math.min(scrollPosition, 70)}px)`,
                transition: 'transform 0.0s ease'
              }
            : {}
          }
        >
          <Link to={pages.home.path} className='flex flex-row items-center'>
            <img
              src='/images/harada_icon.png'
              alt='harada icon'
              className='h-[19px] mr-2'
            />
            <p className='italic text-[#9F8A43] font-garamond text-[25px] font-bold'>
              H.R.D Funeral
            </p>
          </Link>
          <div className='flex flex-row items-center'>
            <Link to={pages.urgent.path}>
              <div className='w-[117px] h-[34px] bg-[#C85554] rounded-[35px] flex justify-center items-center'>
                <img
                  src='/images/circle_exclamation_solid.png'
                  alt='circle-exclamation-solid'
                  className='h-[18px] mr-1'
                />
                <p className='text-[#FFFFFF] text-[12px]'>
                  {pages.urgent.name}
                </p>
              </div>
            </Link>
            <div className='pr-[15px] pl-[18px]' onClick={handleClickModal}>
              <img
                src='/images/hamburger_menu.png'
                alt='hamburger_menu'
                className='h-[18px]'
              />
            </div>
          </div>
        </div>
        {isOpenModal && (
          <div className='pc:hidden h-auto'>
            <div
              className='pc:hidden h-auto fixed top-120 left-0 w-full z-30 bg-[#ffffff]'
              style={{
                transform: `translateY(${-Math.min(scrollPosition, isMobile ? 114 : 75)}px)`,
                transition: 'transform 0.0s ease'
              }}
            >
              <div className='h-[50px] flex flex-row justify-between pl-[15px]'>
                <Link
                  to={pages.home.path}
                  className='flex flex-row items-center'
                >
                  <img
                    src='/images/harada_icon.png'
                    alt='harada icon'
                    className='h-[19px] mr-2'
                  />
                  <p className='italic text-[#9F8A43] font-garamond text-[25px] font-bold'>
                    H.R.D Funeral
                  </p>
                </Link>
                <Link
                  to={pages.urgent.path}
                  className='flex flex-row items-center'
                >
                  <div className='w-[117px] h-[34px] bg-[#C85554] rounded-[35px] flex justify-center items-center'>
                    <img
                      src='/images/circle_exclamation_solid.png'
                      alt='circle_exclamation_solid'
                      className='h-[18px] mr-1'
                    />
                    <p className='text-[#FFFFFF] text-[12px]'>お急ぎの方</p>
                  </div>
                  <div
                    className='pr-[19px] pl-[20px]'
                    onClick={handleClickModal}
                  >
                    <img
                      src='/images/cross.png'
                      alt='cross'
                      className='h-[15px]'
                    />
                  </div>
                </Link>
              </div>
              <div className='ml-5 mt-5 mb-1'>
                <Link to={pages.strengths.path}>
                  <div className='border-t border-[#DDDDDD] h-[64px] flex flex-row items-center justify-between '>
                    <p className='text-[#333333] text-[16px]'>
                      {pages.strengths.name}
                    </p>
                    <img
                      src='/images/arrow.png'
                      alt='arrow'
                      className='h-[22px] mr-[10px]'
                    />
                  </div>
                </Link>
                <Link to={pages.funerals.path}>
                  <div className='border-t border-[#DDDDDD] h-[64px] flex flex-row items-center justify-between '>
                    <p className='text-[#333333] text-[16px]'>
                      H.R.D Funeralの葬儀
                    </p>
                    <img
                      src='/images/arrow.png'
                      alt='arrow'
                      className='h-[22px] mr-[10px]'
                    />
                  </div>
                </Link>
                <Link to={pages.halls.path}>
                  <div className='border-y border-[#DDDDDD] h-[64px] flex flex-row items-center justify-between'>
                    <p className='text-[#333333] text-[16px]'>式場一覧</p>
                    <img
                      src='/images/arrow.png'
                      alt='arrow'
                      className='h-[22px] mr-[10px]'
                    />
                  </div>
                </Link>
                <Link to={pages.events.path}>
                  <div className='border-y border-[#DDDDDD] h-[64px] flex flex-row items-center justify-between'>
                    <p className='text-[#333333] text-[16px]'>
                      {pages.events.name}
                    </p>
                    <img
                      src='/images/arrow.png'
                      alt='arrow'
                      className='h-[22px] mr-[10px]'
                    />
                  </div>
                </Link>
              </div>
              <div className='mx-5 my-[30px] text-center'>
                <p className='text-[#007477] text-[22px] font-serif font-bold'>
                  葬儀のご相談・お見積り
                </p>
                <div
                  className='my-[15px] w-full h-[110px] bg-[#007477] rounded-[66px]'
                  onClick={handleClickContactsModal}
                >
                  <img
                    src='/images/arrow_white.png'
                    alt='arrow_white'
                    className=' absolute h-[12px] right-[48px] bottom-[104px]'
                  />
                  <div className='flex flex-row justify-center items-center py-[17px]'>
                    <img
                      src='/images/phone.png'
                      alt='phone'
                      className='h-[24px] mr-[7px]'
                    />
                    <p className='text-[#FFFFFF] text-[22px]'>お電話での相談</p>
                  </div>
                  <div className='flex flex-row justify-center items-center'>
                    <img
                      src='/images/ribbon.png'
                      alt='ribbon'
                      className='h-[33px]'
                    />
                    <p className='absolute text-[#FFFFFF] text-4'>
                      24時間365日受付中
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='pc:hidden fixed top-[505px] left-0 w-full h-full z-10 bg-[#000000] opacity-60'
              onClick={handleClickModal}
            />
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
