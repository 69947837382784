import React from 'react';
import InfoCardContainer from './InfoCardContainer';
import InfoCard from './InfoCard';

const Services: React.FC<{}> = () => {
  return (
    <InfoCardContainer className='mt-[40px]'>
      <InfoCard
        src={'/images/strengths/ceremony.png'}
        alt={'葬儀を参列する人の写真'}
        heading={'全国葬儀施行実績　6,500件/年間'}
        label={
          <InfoCardLabel
            src={'/images/strengths/altar_icon.png'}
            alt={'お墓のアイコン'}
          />
        }
      >
        <p>ひとつとして同じものはないご葬儀。</p>
        <p>
          大切なご家族を弔うお客様の気持ちに寄り添い、
          想いを込めたセレモニーのお手伝いを誠実に守り続け、
          年間6,500件の実績を誇る葬儀施行を執り行っております。
        </p>
        <p>
          それぞれに異なるかけがえのない想いをひとつひとつかたちにすることを心がけております。
        </p>
        <p>（※2023年実績）</p>
      </InfoCard>
      <InfoCard
        src={'/images/strengths/calculator_lady.png'}
        alt={'電卓を持っている人の写真'}
        heading={'明確な葬儀費用'}
        imagePosition='top'
        label={
          <InfoCardLabel
            src={'/images/strengths/money_icon.png'}
            alt={'お金のアイコン'}
          />
        }
      >
        <p>普段はあまり馴染みのない葬儀にかかる費用。</p>
        <p>
          後悔しないご葬儀を行うためには、
          費用の内訳や内容について理解することが大切です。
          当社ではお客様のご要望に沿ったご提案を行うとともに、
          事前に詳細な見積りを提出しますのでご安心ください。
          さまざまなご希望やご予算にお応えできるよう、
          心のこもったプランをご用意しております。
        </p>
      </InfoCard>
      <InfoCard
        src={'/images/strengths/consultation.png'}
        alt={'相談を受ける夫婦の写真'}
        heading={'地域密着型の葬儀社'}
        label={
          <InfoCardLabel
            src={'/images/strengths/map_icon.png'}
            alt={'日本の地図のアイコン'}
          />
        }
      >
        <p>ご葬儀には、その地域ならではの風習やしきたりがあります。</p>
        <p>
          葬儀社を選ぶ上でその地域性を把握しているかどうかはとても重要なことです。
          大切な人をお見送りするための大切な儀式。地元に根付いた葬儀社だからこそできる、
          きめ細やかなサービスを提供いたします。
        </p>
      </InfoCard>
    </InfoCardContainer>
  );
};

const InfoCardLabel: React.FC<{ src: string; alt: string }> = ({
  src,
  alt
}) => <img src={src} alt={alt} className='h-[70px] w-[70px] object-contain' />;

export default Services;
