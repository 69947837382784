import { useLocation, Outlet, ScrollRestoration } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SupportInformation from './components/common/SupportInformation';
import { pages, titles } from './config/pages';
import ContactsModal from './components/common/ContactsModal';
import Questions from './components/common/Questions';
import { ContactsModalProvider } from './contexts/ContactsModalContext';
import React from 'react';
import { Helmet } from 'react-helmet';
import { ChatPlus } from './components/common/ChatPlus';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.key;
        }}
      />
      <ChatPlus />
      <div className='App'>
        <ContactsModalProvider>
          <Helmet
            title={titles[location.pathname] || titles[pages.home.path]}
          />
          <Header />
          {/*  react routerのchildrenがここ↓に入る */}
          <Outlet />
          <Questions />
          <SupportInformation />
          <ContactsModal />
          <Footer />
        </ContactsModalProvider>
      </div>
    </>
  );
};

export default App;
