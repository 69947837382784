import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const PlanCard: React.FC<{
  planName: string;
  planDescription: string;
  imageName: string;
  attendeeAmount: string;
  hasWake?: boolean;
  hasCondolences?: boolean;
  hasCremation?: boolean;
  className?: string;
  path: string;
}> = ({
  planName,
  planDescription,
  imageName,
  attendeeAmount,
  hasWake,
  hasCondolences,
  hasCremation,
  className,
  path
}) => {
  return (
    <div className={`flex flex-row shadow-normal rounded-b-[9px] ${className}`}>
      <div className='sp:w-full pc:w-[341px] h-[398px] border-[1px] border-[#007477] rounded-[10px]'>
        <div className='w-full h-[128px] bg-[#007477] rounded-t-[7px] flex flex-col items-center justify-evenly'>
          <p className='text-[#FFFFFF] font-serif font-bold text-[36px]'>
            {planName}
          </p>
          <div className='w-[19px] h-[1px] bg-[#ffffff]'></div>
          <p className='text-[#FFFFFF] text-[14px] text-center'>
            {planDescription.split('\\n').map((line, index) => {
              return (
                <Fragment key={index}>
                  {line}
                  {index < planDescription.length - 1 && <br />}
                </Fragment>
              );
            })}
          </p>
        </div>
        <img
          src={`/images/${imageName}`}
          alt={imageName}
          className='w-full sp:w-[696px] sp:max-h-[123px] sp:object-cover sp:object-center'
        />
        <div className='h-[80px] w-full border-b-[1px] border-[#D9D9D9] bg-[#F2F2F2] flex flex-row py-[5px] px-[15px] justify-between'>
          <div className='flex items-center'>
            <p className='text-[#007477] font-bold text-[20px]'>
              {attendeeAmount}名
              <span className='text-[#777777] font-normal text-[14px]'>
                程度
              </span>
            </p>
          </div>
          <div className='flex flex-row items-center'>
            <div
              className={`h-[40px] w-[65px] rounded-[6px] bg-[#9F8A43] flex justify-center items-center ${
                !hasWake && 'opacity-[0.4]'
              }`}
            >
              <p
                className={`text-[#ffffff] font-bold text-[16px] ${
                  !hasWake && 'opacity-[0.6]'
                }`}
              >
                通夜式
              </p>
            </div>
            <div
              className={`h-[40px] w-[65px] rounded-[6px] bg-[#9F8A43] flex justify-center items-center mx-[8px] ${
                !hasCondolences && 'opacity-[0.4]'
              }`}
            >
              <p
                className={`text-[#ffffff] font-bold text-[16px] ${
                  !hasCondolences && 'opacity-[0.6]'
                }`}
              >
                告別式
              </p>
            </div>
            <div
              className={`h-[40px] w-[65px] rounded-[6px] bg-[#9F8A43] flex justify-center items-center ${
                !hasCremation && 'opacity-[0.4]'
              }`}
            >
              <p
                className={`text-[#ffffff] font-bold text-[16px] ${
                  !hasCremation && 'opacity-[0.6]'
                }`}
              >
                火葬
              </p>
            </div>
          </div>
        </div>
        <Link
          to={path}
          className='flex flex-row items-center justify-center h-[67px] cursor-pointer'
        >
          <img src='/images/arrow.png' alt='arrow' className='w-[23px] mr-2' />
          <p className='text-[18px]'>詳細を見る</p>
        </Link>
      </div>
    </div>
  );
};

export default PlanCard;
