import { useEffect } from 'react';

export const ChatPlus = () => {
  const d = document;
  useEffect(() => {
    var s = 'https://app.chatplus.jp/cp.js';
    (d as any)['__cp_d'] = 'https://app.chatplus.jp';
    (d as any)['__cp_c'] = 'e25dd07e_1';
    var a = d.createElement('script'),
      m = d.getElementsByTagName('script')[0];
    a.async = true;
    a.src = s;
    m.parentNode?.insertBefore(a, m);
  }, [d]);
  return <></>;
};
