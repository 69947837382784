import React from 'react';
import { EventType } from './EventBox';
import TagBadge from '../common/TagBadge';
import useDeviceType from '../hooks/useDeviceType';
import EventDetailItem from './EventDetailItem';
import EventScheduleTable from './EventScheduleTable';

type Props = {
  event: EventType;
};

const EventDetail: React.FC<Props> = ({ event }) => {
  const isMobile = useDeviceType(672);

  return (
    <div
      className={`bg-white rounded-lg  border-[#007477] border border-solid w-full  py-4 flex items-start flex-col gap-2 ${isMobile ? 'px-4' : 'px-8'}`}
      style={{ fontFamily: "'Noto Sans JP'" }}
    >
      <div className='flex mt-2 mb-6 gap-2'>
        {event.finished && <TagBadge tag={{ name: '終了', disabled: true }} />}
        {event.tags.map((tag) => (
          <TagBadge key={tag.name} tag={tag} />
        ))}
      </div>
      {[
        { label: '開催日時', value: event.eventDate },
        { label: '開催場所', value: event.location },
        { label: 'お問い合わせ', value: event.contact },
        { label: '受付日時', value: event.reception },
        { label: '参加費', value: event.fee },
        { label: 'イベント内容', value: event?.details?.value }
      ].map((item, index) => (
        <EventDetailItem
          key={index}
          label={item.label}
          value={item.value}
          event={event}
        />
      ))}
      <div className='flex flex-wrap mt-2 gap-4 items-center justify-center'>
        {isMobile && <EventScheduleTable schedule={event?.details?.schedule} />}
      </div>
      <div className='flex flex-wrap mt-2 gap-4 items-center justify-center'>
        {event.imgUrl.map((url, index) => (
          <img
            key={index}
            src={url}
            alt={`event detail ${index}`}
            className='w-full h-auto mt-2 sp:max-w-[350px] pc:max-w-[45%] pc:mr-2'
          />
        ))}
      </div>
    </div>
  );
};

export default EventDetail;
