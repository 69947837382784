import { Link } from 'react-router-dom';
import { pages } from '../../config/pages';

const Contact = () => {
  return (
    <>
      <div className='mt-[50px] justify-center flex sp:hidden'>
        <Link
          to={pages.urgent.path}
          className='h-[173px] w-[1066px] bg-[#FFF1EA] rounded-[24px] flex flex-row justify-between py-6'
        >
          <div className='self-center'>
            <div className='h-[56px] w-[624px] bg-[#C85554] rounded-r-[28px] mb-4 flex items-center'>
              <img
                src='/images/circle_exclamation_solid.png'
                alt='circle-exclamation-solid'
                className='h-[35px] mr-[7px] ml-[53px]'
              />
              <p className='text-[#ffffff] text-[33px] font-bold'>
                ご危篤・ご逝去でお急ぎの方へ
              </p>
            </div>
            <p className='text-[#333333] text-[17px] font-bold ml-[54px]'>
              夜間・早朝でもご対応いたします。
              <br />
              些細な相談でも受付可能ですので、ご遠慮なくお問い合わせください。
            </p>
          </div>
          <div className='flex flex-row items-center mr-11 ml-[30px]'>
            <div className='flex flex-row mr-[14px]'>
              <div className='w-[98px] h-[126px] bg-[#ffffff] rounded-[10px] flex flex-col justify-evenly text-center'>
                <div className='justify-center flex'>
                  <img
                    src='/images/24h365d.png'
                    alt='24h365d'
                    className='h-[56px]'
                  />
                </div>
                <p className='text-[#C85554] text-[17px] font-bold'>
                  24時間
                  <br />
                  365日
                </p>
              </div>
            </div>
            <div className='flex flex-row mr-[14px]'>
              <div className='w-[98px] h-[126px] bg-[#ffffff] rounded-[10px] flex flex-col justify-evenly text-center'>
                <div className='justify-center flex'>
                  <img
                    src='/images/free_call.png'
                    alt='free_call'
                    className='h-[61px]'
                  />
                </div>
                <p className='text-[#C85554] text-[17px] font-bold'>通話無料</p>
              </div>
            </div>
            <div className='flex flex-row mr-[37px]'>
              <div className='w-[98px] h-[126px] bg-[#ffffff] rounded-[10px] flex flex-col justify-evenly text-center'>
                <div className='justify-center flex'>
                  <img
                    src='/images/phone_ok.png'
                    alt='phone_ok'
                    className='h-[58px]'
                  />
                </div>
                <p className='text-[#C85554] text-[17px] font-bold'>携帯OK</p>
              </div>
            </div>
            <img
              src='/images/arrow_red.png'
              alt='arrow_red'
              className='h-[23px]'
            />
          </div>
        </Link>
      </div>
      <div className='flex justify-center pc:hidden'>
        <Link
          to={pages.urgent.path}
          className='h-[250px] rounded-[10px] bg-[#FFF1EA] w-full max-w-[696px] my-[50px] mx-[20px]'
        >
          <div className='mt-[15px] mb-2 h-[77px] w-[313px] rounded-r-[40px] bg-[#C85554] flex flex-row items-center'>
            <img
              src='/images/circle_exclamation_solid.png'
              alt='circle_exclamation_solid'
              className='h-[45px] ml-[27px] mr-[10px]'
            />
            <p className='font-bold text-[23px] text-white'>
              ご危篤・ご逝去で
              <br />
              お急ぎの方へ
            </p>
          </div>
          <div className='flex flex-row items-center justify-between mb-[13px]'>
            <p className='font-bold text-[14px] ml-[23px] mr-[30px]'>
              夜間・早朝でもご対応いたします。
              <br />
              些細な相談でも受付可能ですので、ご遠慮なくお問い合わせください。
            </p>
            <img
              src='/images/arrow_red.png'
              alt='arrow_red'
              className='h-[16px] mr-[13px]'
            />
          </div>
          <div className='flex flex-row justify-between mx-[23px]'>
            <div className='h-[60px] w-[30%] bg-white rounded-[10px] flex items-center justify-center'>
              <p className='font-bold text-[17px] text-[#C85554] text-center'>
                24時間
                <br />
                365日
              </p>
            </div>
            <div className='h-[60px] w-[30%] bg-white rounded-[10px] flex items-center justify-center'>
              <p className='font-bold text-[17px] text-[#C85554]'>通話無料</p>
            </div>
            <div className='h-[60px] w-[30%] bg-white rounded-[10px] flex items-center justify-center'>
              <p className='font-bold text-[17px] text-[#C85554]'>携帯OK</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  );
};

export default Contact;
