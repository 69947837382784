import React from 'react';
import { useContactsModal } from '../../contexts/ContactsModalContext';

const FlowSpeech: React.FC<{
  children: React.ReactNode;
  className?: string;
  showPhoneModalButton?: boolean;
}> = ({ children, className, showPhoneModalButton }) => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <div
      className={`${
        className ?? ''
      } grid grid-cols-[1fr_45px] pc:grid-cols-[1fr_60px] gap-[15px] pc:gap-[18px]`}
    >
      <div className='relative border-[1px] border-gold rounded-lg py-[20px] px-[15px] pc:p-[30px] bg-white'>
        <div className='w-[20px] h-[16px] absolute top-[15px] pc:top-[20px] -right-[20px]'>
          <div className='h-1/2 overflow-hidden relative'>
            <div className='bg-white border-gold h-full border-t-[1px] rotate-[32deg] w-[29px] absolute top-[2px] -left-[15px] pc:-left-[12px]'></div>
          </div>
          <div className='h-1/2 overflow-hidden relative'>
            <div className='bg-white border-gold h-full border-b-[1px] -rotate-[32deg] w-[29px] absolute -top-[2px] -left-[15px] pc:-left-[12px]'></div>
          </div>
        </div>
        <p className='text-gold text-[16px]/[30px] [&>*]:text-gold'>
          {children}
        </p>
        {showPhoneModalButton && (
          <p
            className='text-[16px]/[20px] mt-[15px] cursor-pointer'
            onClick={handleClickContactsModal}
          >
            電話でのご相談・
            <br className='pc:hidden' />
            お問い合わせはこちら{' '}
            <div className='inline-block'>
              <div className='h-[22px] w-[22px] rounded-full bg-gold flex justify-center items-center'>
                <img
                  src='/images/arrow_white.png'
                  alt='右に指す矢印'
                  className='h-[8px]'
                />
              </div>
            </div>
          </p>
        )}
      </div>
      <div className=''>
        <img
          src='/images/funerals/female_icon.png'
          alt='女性のイラストのアイコン'
          className='w-[45px] h-[45px] pc:w-[60px] pc:h-[60px] object-contain'
        />
      </div>
    </div>
  );
};

export default FlowSpeech;
