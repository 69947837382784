import React from 'react';

const SpeechBubble: React.FC<{
  children: React.ReactNode;
  color?: 'green' | 'red';
  arrowClass?: string;
}> = ({ children, color, arrowClass }) => (
  <div className='relative w-fit mb-[12px]'>
    <div
      className={`w-fit relative border-[2px] ${
        color === 'red' ? 'border-[#C85554]' : 'border-primary'
      } rounded-full flex justify-center items-center px-[30px] py-[20px] z-10 bg-opacity-0 text-[15px] [@media(min-width:370px)]:whitespace-nowrap`}
    >
      {children}
    </div>
    <div className='w-[calc(100%-2px)] h-[calc(100%-2px)] absolute left-[5px] top-[5px] bg-white rounded-full z-0'></div>
    <div className='absolute h-full w-full flex justify-center items-end top-0 left-0 z-10'>
      <div
        className={`${
          arrowClass ?? ''
        } -bottom-[11px] h-[15px] w-[15px] overflow-hidden relative`}
      >
        <div
          className={`-top-[13px] -left-[13px] h-[25px] w-[25px] absolute border-r-[2px] ${
            color === 'red' ? 'border-[#C85554]' : 'border-primary'
          } bg-white rotate-45`}
        ></div>
      </div>
    </div>
  </div>
);

export default SpeechBubble;
