import React from 'react';
import { useContactsModal } from '../../contexts/ContactsModalContext';

const ContactsModal = () => {
  const { handleClickContactsModal, isOpenContactsModal } = useContactsModal();
  if (!isOpenContactsModal) return null;
  return (
    <>
      <div className='sp:hidden'>
        <div className='fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-60 z-50 flex items-center justify-center'>
          <div className='bg-white w-[1066px] h-[655px] opacity-100 rounded-[10px] px-[60px] pt-[45px] flex flex-col relative items-center'>
            <img
              src='/images/cross_modal.png'
              alt='cross_modal'
              className='h-[20px] w-[20px] absolute right-[20px] top-[20px] cursor-pointer '
              onClick={handleClickContactsModal}
            />
            <p className='text-[34px] text-primary font-serif font-bold mb-[25px]'>
              問い合わせ先一覧
            </p>
            <div className='flex flex-row justify-center mb-[25px]'>
              <div className='h-[42px] px-[20px] py-[8px] flex items-center bg-gold rounded-[5px] mx-[10px]'>
                <p className='font-bold text-[18px] text-white'>
                  24時間365日受付
                </p>
              </div>
              <div className='h-[42px] px-[20px] py-[8px] flex items-center bg-gold rounded-[5px] mx-[10px]'>
                <p className='font-bold text-[18px] text-white'>
                  通話料・事前相談無料
                </p>
              </div>
              <div className='h-[42px] px-[20px] py-[8px] flex items-center bg-gold rounded-[5px] mx-[10px]'>
                <p className='font-bold text-[18px] text-white'>
                  携帯電話からもOK
                </p>
              </div>
            </div>
            <div className='flex flex-wrap justify-between'>
              {contacts.map(({ prefecture, area, phoneNumber }, index) => {
                return (
                  <div
                    className='flex flex-row w-[48%] mb-[13px] h-[44px]'
                    key={index}
                  >
                    <div className='h-full w-[180px] bg-primary rounded-l-[62px] flex justify-center items-center shadow-normal'>
                      <p className='text-white text-[16px] font-bold'>
                        {prefecture}
                        {area && (
                          <span className='text-white text-[12px] font-bold'>
                            {area}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className='h-full w-[282px] bg-white rounded-r-[62px] border-[1px] border-primary flex flex-row justify-center items-center shadow-normal'>
                      <img
                        src='/images/phone_green.png'
                        alt='phone_green'
                        className='h-[21px] w-[21px] mr-[10px]'
                      />
                      <p className='text-[22px]'>{phoneNumber}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='pc:hidden'>
        <div className='fixed top-0 left-0 w-full h-full bg-[#000000] bg-opacity-60 z-50 flex items-center justify-center'>
          <div className='bg-white w-[335px] h-[90%] opacity-100 rounded-[10px] px-[20px] py-[40px] flex flex-col relative items-center '>
            <img
              src='/images/cross_modal.png'
              alt='cross_modal'
              className='h-[11px] w-[11px] absolute right-[14px] top-[14px] cursor-pointer'
              onClick={handleClickContactsModal}
            />
            <p className='text-[30px] text-primary font-serif font-bold mb-[20px]'>
              問い合わせ先一覧
            </p>
            <div className='flex flex-wrap justify-between'>
              <div className='h-[36px] w-full py-[5px] flex items-center justify-center bg-gold rounded-[5px] mb-[5px]'>
                <p className='font-bold text-[18px] text-white'>
                  24時間365日受付
                </p>
              </div>
              <div className='h-[36px] p-[8px] flex items-center justify-center bg-gold rounded-[5px]'>
                <p className='font-bold text-[14px] text-white'>
                  通話料・事前相談無料
                </p>
              </div>
              <div className='h-[36px] p-[8px] flex items-center justify-center bg-gold rounded-[5px]'>
                <p className='font-bold text-[14px] text-white'>
                  携帯電話からもOK
                </p>
              </div>
            </div>
            <div className='mt-[22px] h-full overflow-y-auto'>
              {contacts.map(({ prefecture, area, phoneNumber }, index) => {
                return (
                  <a
                    className='flex flex-row w-[295px] mb-[15px]'
                    key={index}
                    href={`tel:${phoneNumber}`}
                  >
                    <div className='h-[45px] w-[90px] bg-primary rounded-l-[62px] flex flex-col justify-center items-center shadow-normal'>
                      <p className='text-white text-[16px] font-bold'>
                        {prefecture}
                      </p>
                      {area && (
                        <p className='text-white text-[10px] font-bold'>
                          {area}
                        </p>
                      )}
                    </div>
                    <div className='h-[45px] w-[205px] bg-white rounded-r-[62px] border-[1px] border-primary flex flex-row justify-center items-center shadow-normal'>
                      <img
                        src='/images/phone_green.png'
                        alt='phone_green'
                        className='h-[18px] w-[18px] mr-[10px]'
                      />
                      <p className='text-[20px]'>{phoneNumber}</p>
                    </div>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsModal;

const contacts = [
  { prefecture: '青森県', phoneNumber: '0120-767-660' },
  { prefecture: '山形県', phoneNumber: '0120-567-685' },
  { prefecture: '群馬県', phoneNumber: '0120-69-4109' },
  { prefecture: '栃木県', phoneNumber: '0120-124-058' },
  { prefecture: '茨城県', phoneNumber: '0800-8006-506' },
  { prefecture: '埼玉県', area: '（久喜エリア）', phoneNumber: '0120-008-333' },
  { prefecture: '埼玉県', area: '（吉川エリア）', phoneNumber: '0120-081-940' },
  { prefecture: '東京都', phoneNumber: '0120-594-024' },
  { prefecture: '静岡県', phoneNumber: '0120-136-344' },
  { prefecture: '兵庫県', area: '（山崎エリア）', phoneNumber: '0120-079-632' },
  { prefecture: '兵庫県', area: '（丹波エリア）', phoneNumber: '0120-03-5344' },
  { prefecture: '岡山県', phoneNumber: '0120-831-983' },
  { prefecture: '広島県', area: '（広島市）', phoneNumber: '0120-424-151' },
  { prefecture: '広島県', area: '（福山市）', phoneNumber: '0120-927-923' },
  { prefecture: '愛媛県', phoneNumber: '0120-105-969' }
];
