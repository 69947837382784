import React, { Fragment } from 'react';

const SubHeading: React.FC<{
  variant?: 'h2' | 'h3';
  tagLine?: string;
  children: React.ReactNode;
}> = ({ variant, children, tagLine }) => (
  <div className='flex flex-col justify-center items-center mb-[30px]'>
    {variant === 'h3' ?
      <h3
        className={`text-[#007477] font-serif text-[26px] pc:text-[28px] font-bold text-center`}
      >
        {children}
      </h3>
    : <h2
        className={`text-[#007477] font-serif text-[28px] pc:text-[40px]
      font-bold text-center`}
      >
        {children}
      </h2>
    }
    {tagLine ?
      <p className='text-[#95742E] text-[16px] font-bold font-serif mb-3'>
        {tagLine}
      </p>
    : <div className='mt-[10px] h-[2px] flex gap-[3px]'>
        {[...new Array(variant === 'h3' ? 3 : 6)].map((_element, index) => (
          <Fragment key={index}>
            <div className='w-[7px] h-full bg-gold rounded-full'></div>
            <div className='w-[2px] h-full bg-gold rounded-full'></div>
            <div className='w-[2px] h-full bg-gold rounded-full'></div>
          </Fragment>
        ))}
        <div className='w-[7px] h-full bg-gold rounded-full'></div>
      </div>
    }
  </div>
);

export default SubHeading;
