import React, { useEffect } from 'react';
import Hall from '../components/halls/Hall';
import HallsDataJson from '../data/halls.json';
import { pages } from '../config/pages';
import Breadcrumbs from '../components/common/Breadcrumbs';
import Points from '../components/halls/Points';
import Introduction from '../components/common/Introduction';
import SearchBox from '../components/halls/SearchBox';
import {
  ceremonyTypes,
  ceremonyTypesMap,
  HallType,
  sortHalls
} from '../config/halls';
import NoResults from '../components/halls/NoResults';
import {
  ParamKeyValuePair,
  useLocation,
  useSearchParams
} from 'react-router-dom';

const HallsContainer: React.FC<{}> = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const hallsData = HallsDataJson.data as HallType[];
  const prefectures = hallsData
    .sort(sortHalls)
    .map(({ prefecture }) => prefecture)
    .filter((value, index, array) => array.indexOf(value) === index);

  const [selectedPrefecture, setSelectedPrefectureInternal] = React.useState<
    undefined | string
  >(searchParams.get('prefecture') ?? undefined);
  const setSelectedPrefecture = (newSelectedPrefecture: string | undefined) => {
    const newSearchParams = [
      ['ceremonyType', selectedCeremonyType],
      ['prefecture', newSelectedPrefecture]
    ].filter((param) => param[1]) as ParamKeyValuePair[];
    setSearchParams(newSearchParams);
  };
  const [selectedCeremonyType, setSelectedCeremonyTypeInternal] =
    React.useState<undefined | string>(
      searchParams.get('ceremonyType') ?? undefined
    );
  const setSelectedCeremonyType = (
    newSelectedCeremonyType: string | undefined
  ) => {
    const newSearchParams = [
      ['ceremonyType', newSelectedCeremonyType],
      ['prefecture', selectedPrefecture]
    ].filter((param) => param[1]) as ParamKeyValuePair[];
    setSearchParams(newSearchParams);
  };
  const filteredHalls = hallsData.filter(
    (hall) =>
      (!selectedPrefecture || hall.prefecture === selectedPrefecture) &&
      (!selectedCeremonyType || hall[ceremonyTypesMap[selectedCeremonyType]])
  );
  useEffect(() => {
    setSelectedCeremonyTypeInternal(
      searchParams.get('ceremonyType') ?? undefined
    );
    setSelectedPrefectureInternal(searchParams.get('prefecture') ?? undefined);
  }, [location, searchParams]);
  return (
    <div>
      <Introduction
        title={'式場一覧'}
        align='left'
        extraElement={
          <div className='p-[25px] pc:p-[50px] z-20 w-full max-w-[600px]'>
            <SearchBox
              prefectures={prefectures}
              ceremonyTypes={ceremonyTypes}
              selectedPrefecture={selectedPrefecture}
              setSelectedPrefecture={setSelectedPrefecture}
              selectedCeremonyType={selectedCeremonyType}
              setSelectedCeremonyType={setSelectedCeremonyType}
            />
          </div>
        }
      >
        <p>お葬式で使用する式場は、様々な種類がございます。 </p>
        <p>地域やお客様のご要望をお伺いし、</p>
        <p>最適な式場をご提案させていただきます。</p>
      </Introduction>
      <Breadcrumbs subPages={[pages.halls]} />
      <div
        key={filteredHalls
          .map((hall) => hall.id)
          .reduce((prev, current) => prev + current, '')}
        className='fadeIn mx-auto max-w-[1066px] mt-[30px] mid:mt-[50px] flex flex-wrap justify-center gap-[20px] px-[10px]'
      >
        {filteredHalls.sort(sortHalls).map((hallData) => (
          <Hall key={hallData.id} hallData={hallData} />
        ))}
        {!filteredHalls.length && <NoResults />}
      </div>
      <Points />
    </div>
  );
};

export default HallsContainer;
