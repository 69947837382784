import React from 'react';
import Flow from '../Flow';
import FlowPoint from '../FlowPoint';
import FlowSpeech from '../FlowSpeech';
import FlowElement from '../FlowElement';
import TitledList from '../TitledList';
import FlowFinalElement from '../FlowFinalElement';
import FlowHeader from '../FlowHeader';

const CremationFlow: React.FC<{}> = () => {
  return (
    <div className='mb-[70px]'>
      <FlowHeader
        title='火葬式の流れ'
        backgroundClass={
          "bg-[linear-gradient(180deg,rgba(255,255,255,1)_0%,rgba(255,255,255,0)_33%,rgba(255,255,255,0)_100%),url('/public/images/funerals/incense_sp.png')] " +
          "pc:bg-[linear-gradient(180deg,rgba(255,255,255,1)_0%,rgba(255,255,255,0)_33%,rgba(255,255,255,0)_100%),url('/public/images/funerals/incense.png')]"
        }
        steps={['搬送', 'ご安置', '納棺', '火葬']}
      />
      <Flow>
        <FlowPoint>
          <p>
            最後の時間を大切な人たちと共に故人を見送るため、事前に三親等に含まれる人の連絡先等はまとめておくとよいでしょう。
          </p>
        </FlowPoint>
        <FlowSpeech showPhoneModalButton={true}>
          <p>
            病院で亡くなった場合・自宅で亡くなった場合と、様々なパターンが考えられる中で、問い合わせを実施したり比較をしたりと、対応する必要がある事項が多いため、事前に希望の条件を相談しておくなど、葬儀社の選定なども済ませておくことをお勧めします。
          </p>
        </FlowSpeech>

        <FlowElement
          title='搬送'
          index={1}
          src={'/images/funerals/driver.png'}
          alt={'葬儀車を運転する人のハンドルの写真'}
        >
          <p>
            ご逝去後、看護師により故人様のお体を拭き清めるエンゼルケアを行います。{' '}
          </p>
          <p className='mb-[1.5em]'>
            その後、ご安置する場所（ご自宅・安置施設など）へ故人様を搬送いたします。{' '}
          </p>
          <p>
            事業としてご遺体搬送を行うには、貨物自動車運送事業法に基づき、国土交通省から「一般貨物自動車運送事業」の許可を受けた事業者である必要がございます。{' '}
          </p>
          <p>
            ご自宅へ安置する場合、自家用車で行うことは法律上問題ございませんが、
            専用のストレッチャーを使用することでご遺体への負担が軽減されますので、
            葬儀社へ寝台車による搬送を依頼されたほうがよいでしょう。
            葬儀社へ搬送を依頼した場合、枕付きの防水シーツ、掛け布団が用意されているのが一般的です。{' '}
          </p>
          <p>
            葬儀社の霊安室へ安置する場合は、霊安室の空き状況も含め葬儀社への連絡が必要です。
          </p>
        </FlowElement>
        <FlowPoint>
          <p>
            長距離のご遺体搬送料金は、規程の搬送料金に加えて、高速・有料道路通行料金、ご遺体保全のためのドライアイス、交代要員などが必要な場合の人件費等がかかります。{' '}
          </p>
          <p>
            搬送業者にそのまま葬儀まで依頼をしている場合は、後日葬儀後にまとめてお支払いとなりますが、依頼している葬儀式場へ別の搬送業者を利用される場合は、ご遺体の安置後に別途お支払いをしていただくのが一般的です。
          </p>
        </FlowPoint>
        <FlowPoint>
          <p>
            搬送から実際に葬儀を執り行うまでの準備の関係から、ご自身（喪主）の情報と、故人様の情報、搬送先、ご遺体の状況、宗旨･宗派について、伝えることができるよう準備をしておきましょう。
          </p>
        </FlowPoint>
        <FlowSpeech showPhoneModalButton={true}>
          <p>
            ご遺体の搬送のお電話の際に、ご希望の葬儀形式などをお伺いすることもございます。
          </p>
          <p>
            もし希望の葬儀形式などが決まっている場合は、対応が可能かも含めて問い合わせと同時に聞いておきましょう。
          </p>
        </FlowSpeech>
        <FlowElement
          title='ご安置'
          index={2}
          src={'/images/funerals/rest.png'}
          alt={'葬儀社員の写真'}
        >
          <p>
            通夜式や葬儀式までの一定期間、ご遺体を保管することを安置と言います。
          </p>
          <p>
            ご自宅の場合、北向き又は西向きに安置し、ドライアイスなどを使用してご遺体を保全いたします。
          </p>
          <p className='mb-[1.5em]'>
            葬儀社へ依頼するとドライアイスのほか、枕飾り（焼香用具）の手配もできます。
          </p>
          <p>
            近年では住宅事情などにより葬儀社の霊安室を利用される方が増えております。
            霊安室の場合、ご遺体専用の保冷庫を完備している所も多く、
            ご遺体を凍結することなく安置することが可能です。
          </p>
          <p>
            安置後は連絡が必要な親戚や友人・知人に安置場所をお知らせします。
          </p>
          <TitledList
            className='mt-[20px]'
            variant={'horizontal'}
            title={'【安置施設の主な種類】'}
            elements={['ご自宅', '葬儀社・斎場', '民間の安置施設']}
          />
        </FlowElement>
        <FlowElement
          title='納棺'
          index={3}
          src={'/images/funerals/coffin.png'}
          alt={'納棺の写真'}
        >
          <p>
            納棺は、故人が穢れのないからだで旅立てるように支度を整える儀式です。
          </p>
          <p className='mb-[1.5em]'>
            故人の安らかな旅路を願うだけでなく、遺族が一緒にゆっくりと過ごせる最後の時間でもあります。
          </p>
          <p>
            納棺に立ち会われるのは故人の配偶者、子供、兄弟姉妹が一般的で、故人様のお体を逆さ水（水にお湯を足したぬるま湯）で清拭し、旅支度として手甲や脚絆・足袋・経帷子などを身に着けて差し上げます。
          </p>
          <p>
            その後、立ち会われているご親族様の手により故人をお棺へとお納めし、故人の愛用品など「副葬品」も一緒にお納めします。
          </p>
        </FlowElement>
        <FlowPoint>
          <p>
            近年では「納棺師」によるエンゼルメイク（死化粧）や、故人らしい服に着せ替えるなど、出来るだけ生前のお姿に整えてから納棺をすることが一般的となりました。{' '}
          </p>
          <p>
            故人様の愛用品も一緒にお納めするため、火葬場の規定等で棺の中へ入れられないものなどもございますので、事前に相談をしておきましょう。
          </p>
        </FlowPoint>
        <FlowElement
          title='火葬'
          index={4}
          src={'/images/funerals/cremation_ceremony.png'}
          alt={'骨壺の写真'}
          imageClass='object-[center_top]'
        >
          <TitledList
            className='mb-[20px]'
            variant={'horizontal'}
            title={'【火葬の流れ】'}
            elements={[
              '火葬許可証を渡す',
              '火葬前の儀式（宗教儀礼）',
              '火葬・待機',
              '収骨'
            ]}
          />
          <p>火葬式では、基本的に親族である方が立ち合いをされます。</p>
          <p className='mb-[1.5em]'>
            所要時間は1～2時間程度で、一般的な火葬場の営業時間が朝の9時～16時頃までとなります（火葬場によって変動します）。
          </p>
          <p>
            火葬終了後、収骨室にて「骨上げ」を行います。ひとつの遺骨を2人1組にて竹製と木製の箸を一本ずつ使って拾い、
            骨壷に納めてまいります。喪主から順番に血縁の深い順に行い、
            現世から来世への橋渡しをするという意味合いがあると言われております。
          </p>
          <p>
            収骨後、提出した火葬許可証は「火葬執行」の印が押され、
            「埋葬許可証」となります。埋葬時に必要な書類ですので、
            骨壺と一緒にしておくなど紛失しないようにしましょう。
          </p>
        </FlowElement>
        <FlowSpeech>
          <p>
            担当スタッフが火葬場の予約・役所等への火葬手続きを代行いたします。{' '}
          </p>
          <p>不明点やご不安なことがあれば、担当者へお申し付けください。</p>
        </FlowSpeech>
        <FlowFinalElement>
          <p>ここまでで火葬式の流れは以上となります。 </p>
          <p>
            式が終わった後もお墓や相続・遺品整理など、対応すべき事項は多くあります。{' '}
          </p>
          <p>
            当日の流れは勿論のこと、葬儀が終わった後のサポートについてもご相談可能ですので、
            お気軽にお問い合わせください。
          </p>
          <p>
            希望内容によって最適なご提案をできるよう、スタッフ一同誠心誠意対応をさせていただきます。
          </p>
        </FlowFinalElement>
      </Flow>
    </div>
  );
};

export default CremationFlow;
