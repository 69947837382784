import React from 'react';
import { prefectures } from '../../config/constants';
import { pages } from '../../config/pages';
import { Link } from 'react-router-dom';

const Links: React.FC<{}> = () => {
  return (
    <div className='bg-contrastBg w-full py-[70px] px-[20px]'>
      <div className='max-w-[1066px] mx-auto flex flex-col pc:flex-row justify-center gap-[40px] w-fit'>
        <Window title='葬儀のプラン例を見てみる'>
          <p className='text-center text-[16px]/[30px]'>
            実際に行われた葬儀の事例を交えながら、H.R.D
            Funeralが提供する葬儀プランをご紹介させていただきます。
          </p>
          <div className='flex flex-col pc:flex-row gap-[20px] mt-[23px] flex-wrap pc:flex-nowrap justify-center'>
            <Plan
              src={'/images/strengths/cremation.png'}
              alt={'火葬式の写真'}
              title={'火葬式'}
              href={pages.cremationFuneral.path}
            />
            <Plan
              src={'/images/strengths/private.png'}
              alt={'家族葬の写真'}
              title={'家族葬'}
              href={pages.privateFuneral.path}
            />
            <Plan
              src={'/images/strengths/normal.png'}
              alt={'一般葬儀の写真'}
              title={'一般葬'}
              href={pages.generalFuneral.path}
            />
          </div>
        </Window>
        <Window title='式場を探す'>
          <p className='text-center text-[16px]/[30px]'>
            個性豊かな式場を、各エリアごとにご用意しております。
            ご希望のエリアを選択して式場を確認ください。
          </p>
          <div className='mt-[23px] flex flex-wrap gap-[13px] justify-center'>
            {prefectures.map((prefecture) => (
              <Chip
                key={prefecture}
                text={prefecture}
                href={`${pages.halls.path}?prefecture=${prefecture}`}
              />
            ))}
          </div>
        </Window>
      </div>
    </div>
  );
};

const Window: React.FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children
}) => (
  <div className='max-w-[513px] border-[1px] border-primary rounded-lg overflow-hidden grow-0 pc:w-[50%]'>
    <div className='bg-primary py-[22px]'>
      <p className='text-center text-white text-[24px]/[28px]'>{title}</p>
    </div>
    <div className='m-[28px]'>{children}</div>
  </div>
);

const Plan: React.FC<{
  src: string;
  alt: string;
  title: string;
  href: string;
}> = ({ src, alt, title, href }) => (
  <Link to={href}>
    <div className='border-[2px] border-primary rounded-full pc:rounded-lg overflow-hidden drop-shadow-[0px_3px_0px_#00000029] bg-white'>
      <img
        src={src}
        alt={alt}
        className='w-full object-cover hidden pc:block'
      />
      <div className='text-[18px]/[30px] text-primary text-center px-[22px] py-[12px]'>
        {title}
      </div>
    </div>
  </Link>
);

const Chip: React.FC<{ text: string; href: string }> = ({ text, href }) => (
  <Link to={href} className='grow'>
    <div className='text-center py-[4px] px-[16px] rounded-full border-[2px] border-primary drop-shadow-[0px_3px_0px_#00000029] text-[18px]/[30px] bg-white text-primary'>
      {text}
    </div>
  </Link>
);

export default Links;
