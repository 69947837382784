import React from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import TagBadge from './TagBadge';

type CarouselItem = {
  text: string;
  backgroundColor: string;
  link: string;
};

type CarouselProps = {
  items: CarouselItem[];
  setBannerStatus: React.Dispatch<React.SetStateAction<boolean>>;
};

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  adaptiveHeight: true,
  speed: 500,
  autoplaySpeed: 5000,
  nextArrow: <></>,
  prevArrow: <></>,
  appendDots: (dots: React.ReactNode) => (
    <div style={{ position: 'absolute', bottom: '5px' }}>
      <ul
        style={{
          margin: '0px',
          display: 'flex',
          justifyContent: 'center',
          color: 'white'
        }}
      >
        {dots}
      </ul>
    </div>
  )
};

const TopBanner: React.FC<CarouselProps> = ({ items, setBannerStatus }) => {
  const handleClose = () => {
    setBannerStatus(false);
  };

  return (
    <>
      <button
        onClick={handleClose}
        className='absolute top-0 right-0 m-2 text-white bg-black rounded-full p-1 z-10'
        aria-label='Close'
      >
        ✕
      </button>
      <Slider
        {...settings}
        className='flex justify-center items-center bg-[#007477]'
      >
        {items.map((item, index) => (
          <div
            key={index}
            className={`${item.backgroundColor} gap-2 w-full text-center py-1 px-10 pb-[30px] justify-center items-center `}
            style={{
              backgroundColor: item.backgroundColor,
              width: 'calc(100% - 200px)'
            }}
          >
            <TagBadge tag={{ name: 'NEW' }} />
            <Link
              to={item.link}
              className='self-center hover:underline cursor-pointer text-white '
            >
              <div className='text-[12px] flex text-left justify-center items-center'>
                <span className='two-lines'>{item.text}</span>
              </div>
            </Link>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default TopBanner;
