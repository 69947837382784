import React from 'react';
import { EventType } from './EventBox';
import EventScheduleTable from './EventScheduleTable';
import useDeviceType from '../hooks/useDeviceType';
import { Link } from 'react-router-dom';

type EventDetailItemProps = {
  label: string;
  value?: string | React.ReactNode | object;
  event: EventType;
};

const LocationDetails: React.FC<{ event: EventType }> = ({ event }) => (
  <>
    <Link
      className=' hover:underline cursor-pointer'
      to={event.location.companyUrl}
      target='_blank'
    >
      <div className='text-[#333333] whitespace-pre-wrap  w-full flex text-left items-center justify-start text-[16px]'>
        {event.location.name}
      </div>
    </Link>
    <Link
      className={`flex gap-2 my-2`}
      to={event.location.googleMap}
      target='_blank'
    >
      <div className='text-[#007477] text-[14px] text-left hover:underline cursor-pointer  flex'>
        {'会場へのアクセスはこちらをご確認ください'}
      </div>
      <div className='flex items-center'>
        <img
          src='/images/arrow.png'
          alt='arrow'
          className='h-[15px] mr-[10px]'
        />
      </div>
    </Link>
  </>
);

const EventDetailItem: React.FC<EventDetailItemProps> = ({
  label,
  value,
  event
}) => {
  const isMobile = useDeviceType(672);
  if (!value) return <></>;
  return (
    <div
      className={`w-full flex-row justify-start items-center flex  gap-4 mb-2  text-center `}
    >
      <div className='flex gap-4'>
        <div className='flex min-w-[120px] items-center justify-center w-[120px]  bg-white text-[#439D9F] border  p-2  text-[16px] mr-2'>
          {label}
        </div>
        <div className='flex items-start flex-col justify-center '>
          {label !== '開催場所' && typeof value === 'string' && (
            <div className='text-[#333333] whitespace-pre-wrap  w-full flex text-left items-center justify-start text-[16px]'>
              {value || ''}
            </div>
          )}
          {label === 'お問い合わせ' && (
            <div
              className={`${isMobile && 'flex flex-col  items-start'}  text-[#333333] whitespace-pre-wrap w-full flex text-left  justify-start text-[16px]`}
            >
              <div>{`${event.contact.name} `}</div>
              {isMobile ?
                <>
                  <a
                    className='underline text-left'
                    href={`tel:${event.contact.tel}`}
                  >
                    {`TEL: ${event.contact.tel}`}
                  </a>
                </>
              : <> TEL: {event.contact.tel}</>}
            </div>
          )}
          {!isMobile && label === 'イベント内容' && (
            <EventScheduleTable schedule={event?.details?.schedule} />
          )}
          {label === '開催場所' && <LocationDetails event={event} />}
        </div>
      </div>
    </div>
  );
};

export default EventDetailItem;
