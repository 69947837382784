import React from 'react';
import SmallLinkButton from './SmallLinkButton';
import { useContactsModal } from '../../contexts/ContactsModalContext';

const CompanyFuneralLink: React.FC<{}> = () => {
  const { handleClickContactsModal } = useContactsModal();
  return (
    <div className='max-w-[1066px] rounded-2xl border-[2px] border-primary flex mx-auto overflow-hidden flex-col pc:flex-row'>
      <div className='pc:w-1/3 aspect-[5/2] pc:h-auto overflow-hidden'>
        <img
          src='/images/shasou.png'
          alt='社葬の写真'
          className='object-cover w-full h-full'
        />
      </div>
      <div className='pc:w-2/3 px-[18px] pc:px-[40px] py-[30px] text-center pc:text-left'>
        <div className='mb-[20px]'>
          <h2 className='font-serif text-[23px]/[27px] pc:text-[30px]/[41px] mb-[10px] font-bold'>
            社葬・合同葬についてのご相談も承ります。
          </h2>
          <p className='leading-8'>1000人規模の葬儀を実施した経験をもとに、</p>
          <p className='leading-8'>
            ご要望の葬儀を実現するため尽力いたします。まずはお気軽にご相談下さい。
          </p>
        </div>
        <SmallLinkButton onClick={handleClickContactsModal}>
          <span>
            <img
              src='/images/phone.png'
              alt='電話のアイコン'
              className='inline-block h-[30px] w-[30px] mr-[8px]'
            />
          </span>
          <span>お電話での相談</span>
        </SmallLinkButton>
      </div>
    </div>
  );
};

export default CompanyFuneralLink;
