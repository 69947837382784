import React from 'react';

const FlowPoint: React.FC<{
  className?: string;
  children: React.ReactNode;
}> = ({ className, children }) => {
  return (
    <div
      className={`${
        className ?? ''
      } border-[1px] border-primary rounded-lg bg-[#DEF0EF] font-sans text-[16px]/[30px] text-primary [&>*]:text-primary p-[30px] pc:pl-[55px] relative`}
    >
      <img
        src='/images/funerals/point.svg'
        alt='Pointが書いてあるバナー'
        className='w-[50px] pc:w-[60px] h-[50px] pc:h-[60px] absolute left-[-1px] top-[-1px]'
      />
      {children}
    </div>
  );
};

export default FlowPoint;
