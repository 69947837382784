import React, { createContext, useCallback, useContext, useState } from 'react';

const ContactsModalContext = createContext<{
  handleClickContactsModal: () => void;
  isOpenContactsModal: boolean;
}>({ handleClickContactsModal: () => null, isOpenContactsModal: false });

export const useContactsModal = () => useContext(ContactsModalContext);

export const ContactsModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [isOpenContactsModal, setIsOpenContactsModal] = useState(false);
  const handleClickContactsModal = useCallback(() => {
    setIsOpenContactsModal(!isOpenContactsModal);
  }, [isOpenContactsModal]);

  return (
    <ContactsModalContext.Provider
      value={{
        handleClickContactsModal,
        isOpenContactsModal
      }}
    >
      {children}
    </ContactsModalContext.Provider>
  );
};
