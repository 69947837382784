import React from 'react';
import {
  funeralTypeLabelMap,
  funeralTypePathMap,
  funeralTypes
} from '../../config/constants';
import { Link } from 'react-router-dom';

const Tab: React.FC<{
  funeralType: (typeof funeralTypes)[number];
  children: React.ReactNode;
}> = ({ funeralType, children }) => {
  return (
    <div className='mt-[30px]'>
      <NavigationElement active={funeralType} />
      {children}
    </div>
  );
};

const NavigationElement: React.FC<{ active: string }> = ({ active }) => (
  <div className='max-w-[1066px] flex gap-[10px] px-[20px] h-[80px] mx-auto'>
    {funeralTypes.map((funeralType, index) => (
      <div
        key={index}
        className={`w-1/3 rounded-t-lg bg-[#EDE9E5] 
          ${
            funeralType === active ?
              'bg-gradient-to-b from-[#E8DDB7] to-contrastBg'
            : ''
          }
            `}
      >
        <Link to={funeralTypePathMap[funeralType]}>
          <div className='flex flex-col pc:flex-row justify-center items-center gap-[10px] h-full'>
            <div className='text-gold font-serif text-[20px]'>
              {funeralTypeLabelMap[funeralType]}
            </div>
            <div className='bg-gold rounded-full h-[29px] w-[29px] flex justify-center items-center rotate-90'>
              <img
                src='/images/arrow_white.png'
                alt='下に指す矢印'
                className='w-[5px]'
              />
            </div>
          </div>
        </Link>
      </div>
    ))}
  </div>
);

export default Tab;
