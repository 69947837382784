import { eventList } from '../data/eventList';

export type PageInfo = {
  path: string;
  name: string;
};

export const pages: Record<string, PageInfo> = {
  home: { path: '/', name: 'TOP' },
  urgent: { path: '/urgent', name: 'お急ぎの方' },
  strengths: {
    path: '/strengths',
    name: 'H.R.D Funeralの特長'
  },
  funerals: {
    path: '/funerals',
    name: 'H.R.D Funeralの葬儀'
  },
  generalFuneral: {
    path: '/funerals/general',
    name: 'H.R.D Funeralの葬儀'
  },
  cremationFuneral: {
    path: '/funerals/cremation',
    name: 'H.R.D Funeralの葬儀'
  },
  privateFuneral: {
    path: '/funerals/private',
    name: 'H.R.D Funeralの葬儀'
  },
  halls: {
    path: '/halls',
    name: '式場一覧'
  },
  events: {
    path: '/events',
    name: 'イベント一覧'
  }
};

export const companySiteUrl = 'https://harada-kyodo.jp/companyinfo/';
export const privacyPolicyUrl = 'https://harada-kyodo.jp/privacy';

export const titles = {
  [pages.home.path]: 'H.R.D Funeral 心のかようお葬式',
  [pages.urgent.path]: 'お急ぎの方へ｜H.R.D Funeral 心のかようお葬式',
  [pages.strengths.path]: 'H.R.D Funeralの特長｜H.R.D Funeral 心のかようお葬式',
  [pages.funerals.path]: 'H.R.D Funeralの葬儀｜H.R.D Funeral 心のかようお葬式',
  [pages.generalFuneral.path]:
    '一般葬｜H.R.D Funeralの葬儀｜H.R.D Funeral 心のかようお葬式',
  [pages.privateFuneral.path]:
    '家族葬｜H.R.D Funeralの葬儀｜H.R.D Funeral 心のかようお葬式',
  [pages.cremationFuneral.path]:
    '火葬式｜H.R.D Funeralの葬儀｜H.R.D Funeral 心のかようお葬式',
  [pages.halls.path]: '式場一覧｜H.R.D Funeral 心のかようお葬式',
  [pages.events.path]: 'イベント一覧｜H.R.D Funeral 心のかようお葬式',
  ...eventList.reduce(
    (acc, event) => {
      acc[`/events/${event.pageId}`] = `${event.metaTitle}`;
      return acc;
    },
    {} as Record<string, string>
  )
};
