import React from 'react';
import { getSpecialtiesArray, HallType } from '../../config/halls';

const Hall: React.FC<{ hallData: HallType }> = ({ hallData }) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    const onImageError = (event: ErrorEvent) => {
      (event.target as HTMLImageElement).src = '/images/halls/notFound.jpeg';

      (event.target as HTMLImageElement).removeEventListener(
        'error',
        onImageError as any
      );
      console.log(this);
      return null;
    };
    const img = ref.current as any as HTMLImageElement;
    if (!img) return;
    img.addEventListener('error', onImageError);
  }, []);

  return (
    <div
      className='w-[335px] rounded-[10px] border-[1px] border-[#007477] overflow-hidden bg-[#DEF0EF]'
      key={hallData.id}
    >
      <AWrapper href={hallData.url}>
        <img
          ref={ref}
          src={`/images/halls/${hallData.id}.jpg`}
          alt={hallData.hallName}
          className='w-full rounded-t-[10px] mid:h-[222px] mid:object-cover'
        />
      </AWrapper>
      <div className='flex flex-col items-center justify-evenly bg-white py-[10px]'>
        <AWrapper href={hallData.url}>
          <div className='flex flex-col items-center mb-[5px]'>
            <p
              className={`text-[18px] font-bold ${
                hallData.url && hallData.url !== '' ? 'underline' : ''
              } mb-[5px]`}
            >
              {hallData.hallName}
            </p>
            <p className='text-[14px]'>{hallData.address}</p>
          </div>
        </AWrapper>
        <div className='flex flex-row gap-[8px]'>
          {hallData.offersPrivateFuneral && <GoldPanel>家族葬</GoldPanel>}
          {hallData.offersGeneralFuneral && <GoldPanel>一般葬</GoldPanel>}
          {hallData.offersCremation && <GoldPanel>火葬式</GoldPanel>}
        </div>
      </div>
      <div className='h-[30px] w-full bg-primary flex justify-center items-center text-[14px] text-white'>
        特徴
      </div>
      <div className='flex flex-wrap gap-[8px] px-[20px] py-[10px]'>
        {getSpecialtiesArray(hallData).map((specialty, index) => (
          <WhitePanel key={index}>{specialty}</WhitePanel>
        ))}
      </div>
    </div>
  );
};

const AWrapper: React.FC<{
  children: React.ReactNode;
  href: string | undefined;
}> = ({ href, children }) => {
  if (href && href.length > 0) {
    return (
      <a href={href} target='_blank' rel='noreferrer'>
        {children}
      </a>
    );
  } else {
    return <>{children}</>;
  }
};
const GoldPanel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className='bg-gold flex items-center justify-center rounded-[5px] px-[20px] py-[5px] text-white text-[16px] font-medium'>
    {children}
  </div>
);

const WhitePanel: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className='bg-white flex items-center justify-center rounded-[5px] px-[10px] py-[6px] text-[12px]'>
    {children}
  </div>
);

export default Hall;
