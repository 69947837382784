import React from 'react';
import Ceremonies from '../components/Home/Ceremonies';
import Contact from '../components/Home/Contact';
import Introduction from '../components/Home/Introduction';
import Services from '../components/Home/Services';

const HomeContainer = () => {
  return (
    <>
      <Introduction />
      <Contact />
      <Services />
      <Ceremonies />
    </>
  );
};

export default HomeContainer;
