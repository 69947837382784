import React from 'react';
import SubHeading from '../common/SubHeading';
import TextBlock from '../common/TextBlock';
import Banner from '../common/Banner';

const Points: React.FC<{}> = () => {
  return (
    <div className='bg-contrastBg py-[50px] pc:py-[70px] px-[20px]'>
      <div className='max-w-[1066px] mx-auto'>
        <SubHeading>ご要望にお応えする8つのポイント</SubHeading>
        <TextBlock>
          <p>お葬式はほとんどの方が「初心者」です。 </p>
          <p>費用や手続きが分かりにくいと感じる方は多くいらっしゃいます。</p>
          <p>
            できる限り丁寧に、ご要望をお伺いしながらご希望に沿った、「唯一のお葬式」を手作りしています。
          </p>
        </TextBlock>
        <div className='mt-[40px] flex flex-col items-center gap-[20px]'>
          <InfoRowCard
            alt={'電話しながら、カメラに向いて、笑顔を見せる女性社員２人の写真'}
            index={1}
            headingTop={'24時間365日。いつでも。'}
            headingBottom={'お問い合わせ対応いたします。'}
            imageClass='object-[center_33%]'
          >
            <p>
              24時間365日いつでもお電話でベテランオペレーターが丁寧に対応します。
            </p>
            <p>葬儀に関することは些細なことでも、お気軽にお電話ください。</p>
          </InfoRowCard>
          <InfoRowCard
            alt={'電話しながら、カメラに向いて、笑顔を見せる女性社員２人の写真'}
            index={2}
            headingTop={'費用の疑問'}
            headingBottom={'きちんとお答えします。'}
            imageClass='object-[center_80%]'
          >
            <p>
              ご予算のイメージでお葬式の規模やスタイルをご提案させていただくこともできます。
            </p>
            <p>
              段取りや手続きを少なくして費用を抑えるスタイルから、大規模なお葬式までご用意しています。
            </p>
            <p>
              とはいえ、価格によってサービスの質が変わるといったことはありませんのでご安心ください。
            </p>
          </InfoRowCard>
          <InfoRowCard
            alt={'相談を受ける夫婦の写真'}
            index={3}
            headingTop={'ご要望の演出・宗教・宗派'}
            headingBottom={'お聞かせください。'}
            imageClass='object-[center_33%]'
          >
            <p>
              一般的なお葬式はもちろんですが、様々な規模・形式の葬儀に対応しております。
            </p>
            <p>
              シンプルでスピーディなタイプのお葬式やご家族だけといった小さな規模のお葬式から、
              会社規模のものやお別れ会といったセレモニーも承っています。
            </p>
            <p>（無宗派・仏式・神式、キリスト教、家族葬、社葬等）</p>
          </InfoRowCard>
          <InfoRowCard
            alt={'葬儀会場の写真'}
            index={4}
            headingTop={'家族葬？大規模葬？'}
            headingBottom={'ご要望のプランをご提供します。'}
          >
            <p>
              想いや個性を大切にしたいというご要望におこたえするために、
              新しいお葬式のスタイルをご提案しています。
            </p>
            <p>
              お葬式の基本サービスをベースに、
              様々なサービスやアイテムを自由に選んで組み合わせて、
              もっとオリジナリティのある個性豊かなお葬式を作れるサービスです。
            </p>
          </InfoRowCard>
          <InfoRowCard
            alt={'スーツと白い手袋をはめている葬儀社員の写真'}
            index={5}
            headingTop={'経験豊富な'}
            headingBottom={'葬祭ディレクター多数在籍'}
            imageClass='object-[center_28%]'
          >
            <p>
              専属の「葬祭ディレクター技能審査（厚生労働省認定）1級・2級葬祭ディレクター」がすべてをお手伝いします。
            </p>
          </InfoRowCard>
          <InfoRowCard
            alt={'お茶を注いでいる茶瓶の写真'}
            index={6}
            headingTop={'創業100年を超えるハラダ製茶だからこそ提供できる'}
            headingBottom={'品質の高い返礼品'}
            imageClass='object-[center_60%]'
          >
            <p>
              ご参列いただいた皆様に感謝の気持ちをお伝えします。
              各種返礼品を豊富にご用意しております。
            </p>
            <p>
              日本一の茶産地静岡をはじめ、全国の茶産地で大切に育てられたお茶の中から、
              厳選された自慢の銘茶をお届けします。
            </p>
            <p>
              自社農場・契約農場においてGAP認証を取得しており、
              食品安心と環境に配慮した原料を使用しております。
            </p>
          </InfoRowCard>
          <InfoRowCard
            alt={'贅沢な料理の写真'}
            index={7}
            headingTop={'お料理をはじめ'}
            headingBottom={'まごころ込めたおもてなしの心'}
          >
            <p>真心をこめたお料理でおもてなしいたします。</p>
            <p>
              厳選した四季折々の旬の食材で、それぞれの場にふさわしい内容のお料理を各種取り揃えております。
            </p>
          </InfoRowCard>
          <InfoRowCard
            alt={'パソコンの画面を見ている２人の写真'}
            index={8}
            headingTop={'遠方の方・都合のつかない方も'}
            headingBottom={'お別れの場をご提供いたします。'}
            imageClass='object-[center_70%]'
          >
            <p>
              都合がつかずご参列いただけなかった方へも、オンライン上で故人様とのお別れの場を設けることができます。
            </p>
            <p>
              追悼メッセージや思い出のお写真等をアップし、葬儀への直接の参加が難しい場合でも、故人様との最後のお時間をご提供いたします。
            </p>
          </InfoRowCard>
        </div>
      </div>
    </div>
  );
};

const InfoRowCard: React.FC<{
  alt: string;
  index: number;
  headingTop: string;
  headingBottom: string;
  imageClass?: string;
  children: React.ReactNode;
}> = ({ alt, index, headingTop, headingBottom, imageClass, children }) => (
  <div
    className={`pc:flex pc:even:flex-row pc:odd:flex-row-reverse p-[10px] bg-white rounded-lg justify-between max-w-[700px] pc:max-w-none w-full gap-[10px]`}
  >
    <div className='relative'>
      <Banner
        className='absolute -top-[18px] pc:hidden'
        topText={'Point'}
        bottomText={index.toString().padStart(2, '0')}
      />
      <img
        src={`/images/strengths/point_${index
          .toString()
          .padStart(2, '0')}_image.png`}
        alt={alt}
        className={`${
          imageClass ?? ''
        } max-h-[250px] h-[140px] pc:h-auto rounded-lg w-full pc:w-auto object-cover`}
      />
    </div>
    <div className='mx-[20px] mb-[20px] pc:w-[51%]'>
      <div className='flex mb-[15px] relative'>
        <Banner
          className='absolute -top-[19px] hidden pc:block'
          topText={'Point'}
          bottomText={index.toString().padStart(2, '0')}
        />
        <div className='m-[5px] ml-[85px]'>
          <h3 className='mt-[20px]'>
            <p className='text-primary text-[18px]/[24px] pc:text-[23px]/[28.8px] tracking-[0.46px]'>
              {headingTop}
            </p>
            <p className='text-primary text-[20px]/[21px] pc:text-[28px]/[28.8px] tracking-[0.56px] mt-[5px] font-bold'>
              {headingBottom}
            </p>
          </h3>
        </div>
      </div>
      <div>{children}</div>
    </div>
  </div>
);

export default Points;
