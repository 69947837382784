import React from 'react';
import useDeviceType from '../hooks/useDeviceType';
import { useNavigate } from 'react-router';
import TagBadge from '../common/TagBadge';
import EventDetailItem from './EventDetailItem';
import { EventScheduleItemProps } from './EventScheduleTable';

export type EventType = {
  pageId: number;
  title: string;
  metaTitle: string;
  eventDate: string;
  finished?: boolean;
  location: {
    name: string;
    companyUrl: string;
    googleMap: string;
  };
  contact: {
    name: string;
    tel: string;
  };
  reception: string;
  fee: string;
  imgUrl: string[];
  tags: Array<{ name: string; disabled?: boolean }>;
  details?: {
    value: string;
    schedule?: Array<EventScheduleItemProps>;
  };
};

type Props = {
  event: EventType;
  eventId: number;
};

const EventBox: React.FC<Props> = ({ event, eventId }) => {
  const isMobile = useDeviceType(672);
  const navigate = useNavigate();
  return (
    <div
      className='flex justify-center  w-full'
      style={{
        fontFamily: "'Noto Sans JP"
      }}
    >
      {isMobile ?
        <div className='w-fullbg-[#0d0e0e] py-4'>
          <div className=' flex text-center items-center justify-center'>
            <img
              className='cursor-pointer w-full rounded-t-2xl h-[300px] object-cover'
              src={event.imgUrl[0]}
              alt='eventImg'
              onClick={() => {
                navigate(`/events/${eventId}`);
              }}
            />
          </div>
          <div className='flex flex-col p-4 gap-2 bg-[#DEF0EF]'>
            <div className='flex flex-wrap gap-2'>
              {event.finished && (
                <TagBadge tag={{ name: '終了', disabled: true }} />
              )}
              {event.tags.map((tag) => (
                <TagBadge
                  key={tag.name}
                  tag={{ name: tag.name, disabled: tag?.disabled }}
                />
              ))}
            </div>
            <div
              className='text-[#007477] font-bold text-[16px] text-left cursor-pointer hover:underline '
              onClick={() => {
                navigate(`/events/${eventId}`);
              }}
            >
              {event.metaTitle}
            </div>
          </div>
          <div className='bg-[#DEF0EF] w-full p-4 flex items-start flex-col justify-center rounded-b-2xl'>
            {[
              { label: '開催日時', value: event.eventDate },
              { label: '開催場所', value: event.location },
              { label: 'お問い合わせ', value: event.contact },
              { label: '受付日時', value: event.reception },
              { label: '参加費', value: event.fee }
            ].map((item, index) => (
              <EventDetailItem
                key={index}
                label={item.label}
                value={item.value}
                event={event}
              />
            ))}
          </div>
        </div>
      : <div className='w-full flex  bg-[#DEF0EF] gap-5 rounded-2xl'>
          <div className='w-[500px] rounded-l-lg h-full overflow-hidden'>
            <img
              className='object-cover w-full h-full overflow-hidden cursor-pointer'
              src={event.imgUrl[0]}
              alt='eventImg'
              onClick={() => {
                navigate(`/events/${eventId}`);
              }}
            />
          </div>
          <div className='w-full flex flex-col'>
            <div className='flex flex-col mt-2 mx-2 py-2 gap-2'>
              <div className='flex gap-2'>
                {event.finished && (
                  <TagBadge tag={{ name: '終了', disabled: true }} />
                )}
                {event.tags.map((tag) => (
                  <TagBadge
                    key={tag.name}
                    tag={{ name: tag.name, disabled: tag?.disabled }}
                  />
                ))}
              </div>
              <div
                className='text-[#007477] font-bold text-[28px] mt-3 text-left cursor-pointer hover:underline whitespace-break-spaces'
                onClick={() => {
                  navigate(`/events/${eventId}`);
                }}
              >
                {event.metaTitle}
              </div>
            </div>
            <div className='bg-[#DEF0EF] w-full px-2 py-4 flex items-start flex-col gap-1 rounded-b-lg'>
              {[
                { label: '開催日時', value: event.eventDate },
                { label: '開催場所', value: event.location },
                { label: 'お問い合わせ', value: event.contact },
                { label: '受付日時', value: event.reception },
                { label: '参加費', value: event.fee }
              ].map((item, index) => (
                <EventDetailItem
                  key={index}
                  label={item.label}
                  value={item.value}
                  event={event}
                />
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default EventBox;
