import React from 'react';

const NoResults: React.FC<{}> = () => {
  return (
    <div className='w-full py-[15px] px-[30px]'>
      <div className='mx-auto max-w-[866px] w-full'>
        <div className='text-[16px]/[30px] font-sans text-center mb-[70px]'>
          <p>選択いただいた条件に一致する式場が見つかりませんでした。 </p>
          <p>
            お手数ですが、選択内容を変更いただき再度検索をお願いいたします。
          </p>
        </div>
        <div className='rounded-lg border-[1px] border-primary p-[20px]'>
          <div className='font-serif font-bold text-[16px] [&>*]:text-primary mb-[30px] text-center'>
            <p>式場をご検討される際はお気軽にお電話ください。</p>
            <p>
              ご要望に合わせて、最適な式場をいつでも無料でご提案いたします。
            </p>
          </div>
          <CallButton />
        </div>
      </div>
    </div>
  );
};

const CallButton = () => (
  <div className='h-[80px] mx-auto max-w-[450px] bg-primary rounded-[62px] flex justify-center items-center shadow-normal relative'>
    <div className='grid grid-cols-[1fr_20px] gap-[10px] p-[10px] w-full'>
      <div className='flex gap-[10px] justify-center'>
        <div className='pc:w-[45px] w-[30px]'>
          <img
            src='/images/phone.png'
            alt='電話のアイコン'
            className='h-full w-full object-contain'
          />
        </div>
        <div className='text-white font-bold pc:text-[24px] text-[22px] flex items-center'>
          お電話での相談
        </div>
      </div>
      <div className='flex items-center'>
        <img
          src='/images/arrow_white.png'
          alt='右へ指す矢印'
          className='w-[11px] absolute right-[20px]'
        />
      </div>
    </div>
  </div>
);

export default NoResults;
