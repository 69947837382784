import React from 'react';
import SubHeading from '../common/SubHeading';
import TitledList from './TitledList';

const FuneralExample: React.FC<{
  title: string;
  listTitle: string;
  listElements: Array<string>;
  services: Array<string>;
  amountOfPeopleText: React.ReactNode;
  funeralTypeText: React.ReactNode;
  funeralStyleText: React.ReactNode;
}> = ({
  services,
  title,
  listTitle,
  listElements,
  amountOfPeopleText,
  funeralTypeText,
  funeralStyleText
}) => {
  return (
    <div className='rounded-lg bg-white overflow-hidden mt-[40px]'>
      <div className='p-[15px] pc:p-[30px] '>
        <SubHeading variant='h3'>{title}</SubHeading>
        <div className='flex gap-[20px] justify-between flex-col pc:flex-row'>
          <div className='grid gap-[13px] pc:min-w-[430px]'>
            <Element title={'人数の目安'} text={amountOfPeopleText} />
            <Element title={'葬儀種別'} text={funeralTypeText} />
            <Element title={'形式'} text={funeralStyleText} />
          </div>
          <TitledList
            className='grow'
            variant={'vertical'}
            title={listTitle}
            elements={listElements}
          />
        </div>
      </div>
      <div className='bg-[#DEF0EF] py-[17px] px-[30px] flex items-center gap-[10px] flex-wrap'>
        <div className='text-primary w-full mid:w-fit'>利用サービス：</div>
        {services.map((service, index) => (
          <div key={index} className='rounded-md text-[14px] p-[8px] bg-white'>
            {service}
          </div>
        ))}
      </div>
    </div>
  );
};

const Element: React.FC<{
  title: string;
  text: React.ReactNode;
}> = ({ title, text }) => (
  <div className='flex rounded-lg bg-contrastBg overflow-hidden'>
    <div className='min-w-[115px] w-1/4 text-white bg-primary flex justify-center items-center'>
      {title}
    </div>
    <div className='w-3/4 flex justify-center items-center text-primary p-[12px] text-[18px] font-bold'>
      {text}
    </div>
  </div>
);

export default FuneralExample;
