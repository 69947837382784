import React from 'react';

const Flow: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className='relative pt-[40px]'>
      <div className='flex absolute top-0 left-0 w-full h-full -z-0'>
        <div className='border-r-[4px] border-[#C7BA8B] w-1/2'></div>
        <div className='border-l-[4px] border-[#C7BA8B] w-1/2'></div>
      </div>
      <div className='relative z-10 gap-[25px] flex flex-col'>{children}</div>
    </div>
  );
};

export default Flow;
