import React from 'react';
import Introduction from '../components/common/Introduction';

import Breadcrumbs from '../components/common/Breadcrumbs';
import { pages } from '../config/pages';
import CompanyFuneralLink from '../components/Funerals/CompanyFuneralLink';
import { Outlet } from 'react-router-dom';

const FuneralsContainer: React.FC = () => {
  return (
    <div>
      <Introduction title={pages.funerals.name}>
        <p>
          いざ葬儀を執り行うとなると、やるべきことが多く、休む間もなく次々にやって来ます。
        </p>
        <p>
          ここでは、H.R.D
          Funeralが提供する葬儀の一般的な流れと、事例をご紹介いたします。
        </p>
      </Introduction>
      <Breadcrumbs subPages={[pages.funerals]} />
      <Outlet />
      <div className='mt-[70px] w-full px-[20px]'>
        <CompanyFuneralLink />
      </div>
    </div>
  );
};

export default FuneralsContainer;
